<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useCounterStore } from "@/stores/counter";
import { useTimeListLogic } from "@/stores/timeListLogic";
const memberService = useMemberService();
const { t } = useI18n();
const store = useCounterStore();
const timeListLogic = useTimeListLogic();
const {
  TotalShareFsLogPage,
  ShareFsLogPage,
  CurrentShareFsLog,
  ShareFsLogCount,
  defaultShareFsLog,
} = storeToRefs(memberService);

function changeStartTime() {
  if (
    !defaultShareFsLog.value.sEndTime ||
    defaultShareFsLog.value.sEndTime <= defaultShareFsLog.value.sStartTime
  ) {
    const getStartTime = new Date(defaultShareFsLog.value.sStartTime);
    getStartTime.setMonth(getStartTime.getMonth() + 1);
    defaultShareFsLog.value.sEndTime = getStartTime.toISOString().slice(0, 10);
  }
}

//#region 點擊查詢時間
interface TimeList {
  title: string;
  fn: Function;
  isSelect: boolean;
}
const timeLists = ref<TimeList[]>([
  {
    title: t("MemberFunction.ShareMakeFortune.Yesterday"),
    fn: getYesterdayShareFsLog,
    isSelect: false,
  },
  {
    title: t("MemberFunction.ShareMakeFortune.Today"),
    fn: getTodayShareFsLog,
    isSelect: true,
  },
  {
    title: t("MemberFunction.ShareMakeFortune.LastWeek"),
    fn: getLastWeekShareFsLog,
    isSelect: false,
  },
  {
    title: t("MemberFunction.ShareMakeFortune.ThisWeek"),
    fn: getThisWeekShareFsLog,
    isSelect: false,
  },
  {
    title: t("MemberFunction.ShareMakeFortune.ThisMonth"),
    fn: getThisMonthShareFsLog,
    isSelect: false,
  },
]);
function selectTime(index: number) {
  timeLists.value.forEach((time, timeIndex) => {
    time.isSelect = false;
    if (timeIndex === index) {
      time.isSelect = true;
    }
  });
}

const today = new Date();
const thirtyDaysAgo = new Date(today);
thirtyDaysAgo.setDate(today.getDate() - 31);
const formatDate = (date: any) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};
const minDate = ref(formatDate(thirtyDaysAgo));
const maxDate = ref(formatDate(today));

function getYesterdayShareFsLog() {
  const today = new Date();
  const day = today.getDay();
  const EndDay = timeListLogic.getCashTypeEndTimeDay(day - 1);
  defaultShareFsLog.value.sStartTime = timeListLogic.getDataDate(day - 1);
  defaultShareFsLog.value.sEndTime = timeListLogic.getDataDate(EndDay);

  selectTime(0);
  getShareFsLog();
}
function getTodayShareFsLog() {
  const today = new Date();
  const day = today.getDay();
  const EndDay = timeListLogic.getCashTypeEndTimeDay(day);
  defaultShareFsLog.value.sStartTime = timeListLogic.getDataDate(day);
  defaultShareFsLog.value.sEndTime = timeListLogic.getDataDate(EndDay);

  selectTime(1);
  getShareFsLog();
}
function getLastWeekShareFsLog() {
  const EndDay = timeListLogic.getCashTypeEndTimeDay(0);
  defaultShareFsLog.value.sStartTime = timeListLogic.getDataDate(-6);
  defaultShareFsLog.value.sEndTime = timeListLogic.getDataDate(EndDay);

  selectTime(2);
  getShareFsLog();
}
function getThisWeekShareFsLog() {
  const EndDay = timeListLogic.getCashTypeEndTimeDay(7);
  defaultShareFsLog.value.sStartTime = timeListLogic.getDataDate(1);
  defaultShareFsLog.value.sEndTime = timeListLogic.getDataDate(EndDay);

  selectTime(3);
  getShareFsLog();
}

function getThisMonthShareFsLog() {
  defaultShareFsLog.value.sStartTime = timeListLogic.getMonthFirthDate();
  defaultShareFsLog.value.sEndTime = timeListLogic.getMonthLastDate();

  selectTime(4);
  getShareFsLog();
}
//#endregion

function goShareFsLogPage(page: number) {
  memberService.goShareFsLogPage(page);
}
async function getShareFsLog() {
  await memberService.getShareFsLog();
}

onMounted(() => {
  memberService.toShareFsLog();
});
</script>
<template>
  <div>
    <div class="flex flex-col md:flex-row items-center">
      <div class="flex justify-between items-center w-full my-0 col-span-3">
        <label class="search-date-label relative w-full md:w-[16vw]">
          <input
            @change="changeStartTime"
            :min="minDate"
            :max="maxDate"
            type="date"
            v-model="defaultShareFsLog.sStartTime"
            class="search-date-border text-primary text-[12px] w-full h-[100%] md:h-[3dvh] top-0 left-0 bg-transparent text-center bg-cover bg-center placeholder:text-primary" />
        </label>
        <span
          class="text-primary text-[12px] md:text-[14px] mx-[2vw] my-[1vh]"
          >{{ $t("MemberFunction.ShareMakeFortune.To") }}</span
        >
        <label class="search-date-label relative w-full md:w-[16vw]">
          <input
            type="date"
            :min="minDate"
            :max="maxDate"
            v-model="defaultShareFsLog.sEndTime"
            class="search-date-border text-primary text-[12px] w-full h-[100%] md:h-[3dvh] top-0 left-0 bg-transparent text-center bg-cover bg-center placeholder:text-primary" />
        </label>
        <button @click="getShareFsLog" class="ml-[12px]">
          <img
            src="@image/memberFunction/08/search_btn.png"
            alt=""
            class="h-auto md:h-[3dvh]" />
        </button>
      </div>
    </div>
    <ul
      class="flex justify-center md:justify-between items-center my-[1dvh] px-0">
      <li
        v-for="time in timeLists"
        :key="time.title + 'shareFeedback'"
        class="flex justify-center md:justify-start items-center">
        <button
          class="group relative w-[80%] md:w-[6em] xl:w-[7em] text-[12px] md:text-[14px] mr-0"
          @click="time.fn"
          :class="{
            'text-primary': !time.isSelect,
            'text-secondary': time.isSelect,
          }">
          <img
            src="@image/memberFunction/common/會員選擇無.png"
            alt=""
            v-show="!time.isSelect" />
          <img
            src="@image/memberFunction/common/會員選擇.png"
            alt=""
            v-show="time.isSelect" />
          <p
            class="w-[40px] absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2">
            {{ time.title }}
          </p>
        </button>
      </li>
    </ul>

    <div class="relative m-3 md:m-0">
      <div
        class="absolute grid grid-cols-3 text-primary text-[12px] md:text-[16px] w-full px-2 top-1 md:top-[2dvh] md:px-[1vw]">
        <p class="text-start">
          {{ $t("MemberFunction.ShareMakeFortune.MemberAccount") }}
        </p>
        <p class="text-center">
          {{ $t("MemberFunction.ShareMakeFortune.ValidBetAmount") }}
        </p>
        <p class="text-end">
          {{ $t("MemberFunction.ShareMakeFortune.RebateAmount") }}
        </p>
      </div>
      <div class="">
        <img
          src="@image/memberFunction/03/search_bg_head.png"
          alt=""
          class="" />
        <div
          class="bg-black h-[420px] w-full px-2 rounded-none md:rounded-b-[12px]">
          <p
            class="text-white/80 text-[12px] md:text-[16px] absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2"
            v-if="ShareFsLogCount <= 0">
            <img
              src="@image/memberFunction/文字框/no_file.png"
              alt=""
              class="w-[35px] mx-auto" />
            {{ $t("MemberFunction.ShareMakeFortune.NoData") }}
          </p>
          <table class="w-full text-primary/80 text-[12px] md:text-[16px]">
            <tbody class="">
              <tr
                v-for="(value, index) in CurrentShareFsLog"
                :key="'shareList' + value.sAccount + index"
                class="border-b last:border-0 border-white/30">
                <td class="text-start w-[33%] h-[40px]">
                  {{ value.sAccount }}
                </td>
                <td class="text-center w-[33%] h-[40px]">
                  {{ store.getClearPotAndAddCommas(value.nBetMoney) }}
                </td>
                <td
                  class="text-end w-[33%] h-[40px] font-bold text-[14px] md:text-[18px]"
                  :class="{
                    'text-primary': parseInt(value.nFsMoney) === 0,
                    'text-red-600':
                      parseInt(value.nFsMoney) && parseInt(value.nFsMoney) < 0,
                    'text-green-600':
                      parseInt(value.nFsMoney) && parseInt(value.nFsMoney) > 0,
                  }">
                  {{ store.getClearPotAndAddCommas(value.nFsMoney) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        class="flex items-center justify-center absolute bottom-[10px] left-1/2 -translate-x-1/2 text-[12px]">
        <button
          type="button"
          :disabled="ShareFsLogPage === 1"
          @click="goShareFsLogPage(ShareFsLogPage - 1)"
          class="w-[10px] md:w-[14px]">
          <img src="@image/memberFunction/common/頁面跳轉.png" alt="" />
        </button>
        <ul class="flex text-primary">
          <li
            v-for="index in TotalShareFsLogPage"
            :key="`mailPage${index}`"
            class="mx-[6px] text-[12px] md:text-[14px]">
            <button
              @click="goShareFsLogPage(index)"
              class="font-bold"
              :disabled="index === ShareFsLogPage"
              :class="
                index === ShareFsLogPage
                  ? 'text-primary'
                  : 'text-primary/50 hover:text-primary'
              ">
              {{ index }}
            </button>
          </li>
        </ul>
        <button
          type="button"
          :disabled="ShareFsLogPage === TotalShareFsLogPage"
          class="rotate-180 w-[10px] md:w-[14px]"
          @click="goShareFsLogPage(ShareFsLogPage + 1)">
          <img src="@image/memberFunction/common/頁面跳轉.png" alt="" />
        </button>
      </div>
    </div>
  </div>
</template>
