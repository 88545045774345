import { ref, computed, watch } from "vue";
import axios from "axios";
import { defineStore } from "pinia";
import { useI18n } from "vue-i18n";
// import { useRouter } from 'vue-router';
import router from "@/router/index";
import { useLogin } from "../login";
import { useLanguagesStore } from "../languages";
import { useCounterStore } from "../counter";
import { useErrorAlert } from "../errorAlert";
import { useLoadingPage } from "../loadingPage";
import { useMaintain } from "@/stores/maintain";
import { useTimeListLogic } from "@/stores/timeListLogic";
import * as APIfn from "@/assets/API/API";
import * as APItypes from "@/assets/API/APItype";

interface MailFormData {
  sEmail: string;
}
interface LineFormData {
  sLineId: string;
}
interface AddPromotionFormData {
  sTitle: string;
  nRank: string;
  ActGame: string;
  GameType1: string;
  Baccarat: string;
  APIGame: string;
  GameType2: string;
  TogetherPer: string;
}
interface AddBankFormData {
  sPay_num: string;
  nPay_card: string;
  sPay_address: string;
  sBranchCode: string;
  nBankType: number;
  sFile0: any;
  sFile1: any;
}
interface AddUSDTFormData {
  nPay_usdt: string;
  sPay_address: string;
}
interface WithdrawPageFromData {
  withdrawal: string;
  withdrawal_Type: string;
  nMoney: string;
  sPassword: string;
  nTime: string;
}
interface WithdrawUSDTPageFromData {
  withdrawal: string;
  nMoney: string;
  sPassword: string;
  nTime: string;
}
interface MemberCompanyDepositFormData {
  nPay: string;
  nMoney: string;
  sMemo: string;
}
interface AddStoreDepositFormData {
  sCode: string;
  sCounty: string;
  sShop: string;
  sStore: string;
}
interface OnlineDepositPostData {
  nId: string;
  sPay_name: string;
  sNickname: string;
  nMax: string;
  nMin: string;
  sGids: string;
  sPayType: string;
  nMoney: string;
  t: string;
  a: string;
  CVS: string | undefined;
  StorePayment: string | undefined;
  BarcodePayment: string | undefined;
  TelecomPayment: string | undefined;
}
interface OnlineDepositPost2Data {
  nId: string;
  sPay_name: string;
  sNickname: string;
  nMax: string;
  nMin: string;
  sGids: string;
  nMoney: string;
  t: string;
  a: string;
  postUrl: string;
  aTunnel: string;
}
interface TransferPageData {
  nId: string;
  money: string;
  pwd: string;
}
interface USDTFormData {
  nPay: string;
  nMoney: number;
  sInputAddress: string;
}
export const useMemberService = defineStore("memberService", () => {
  const { t } = useI18n();
  const languages = useLanguagesStore();
  // const router = useRouter();
  const login = useLogin();
  const store = useCounterStore();
  const errorAlert = useErrorAlert();
  const loadingPage = useLoadingPage();
  const maintain = useMaintain();
  const timeListLogic = useTimeListLogic();
  const EndDay = () => {
    return timeListLogic.getCashTypeEndTimeDay(timeListLogic.getTodayDay());
  };
  async function CheckLogin(success: () => void, fail: () => void) {
    await login.CheckMUC(success, fail);
  }

  // APP會員功能
  async function toMemberAccount() {
    loadingPage.openLoadingPage();
    await CheckLogin(successMemberAccount, failMemberAccount);
  }
  async function successMemberAccount() {
    // console.log("successMemberAccount");
    await getVipListData();

    loadingPage.closeLoadingPage();
    return;
  }
  function failMemberAccount() {
    // console.log("failMemberAccount");
    router.push({ name: "login" });

    loadingPage.closeLoadingPage();
    return;
  }

  // 取得信箱已讀數量
  const MailCount = ref<string>("0");
  async function getMailCount() {
    const m_user_c = login.CookieMUC();
    const postData = {
      m_user_c,
    };
    const res: APItypes.MailBoxCountResponse = await APIfn.postMailBoxCount(
      postData
    );
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    switch (res.type) {
      case "0":
        MailCount.value = res.dataList[0].count;
        break;
      default:
        MailCount.value = res.dataList[0].count;
        break;
    }
  }

  //#region 會員管理 頁面
  async function toMemberManagement() {
    loadingPage.openLoadingPage();
    await CheckLogin(successMemberManagement, failMemberManagement);
  }
  async function successMemberManagement() {
    // console.log("successMemberManagement");
    // await getPhoneMail();
    await getCheckPwdBankAboutMemberManagement();

    loadingPage.closeLoadingPage();
    return;
  }
  function failMemberManagement() {
    // console.log("failMemberManagement");
    router.push({ name: "login" });

    loadingPage.closeLoadingPage();
    return;
  }

  // 會員管理-修改密碼
  // 有無交易密碼
  const hasTradePwd = ref<boolean>(false);
  async function getCheckPwdBankAboutMemberManagement() {
    const m_user_c = login.CookieMUC();
    const postData = {
      m_user_c,
    };
    const res = await APIfn.postCheckPwdBank(postData);
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    // console.log(res, "getCheckPwdBankData");
    switch (res.type) {
      case "0":
        hasTradePwd.value = true;
        break;
      case "1":
        hasTradePwd.value = false;
        break;
      case "2":
        hasTradePwd.value = true;
        break;
      default:
        hasTradePwd.value = false;
        break;
    }
  }
  // 修改密碼
  const changepwdMsg = ref<string>("");
  function ClearChangepwdMsg() {
    changepwdMsg.value = "";
  }
  async function ChangePassword(data: APItypes.ChangepwdData) {
    const res: APItypes.ChangepwdResponse = await APIfn.postChangepwd(data);
    // console.log(res, "ChangePassword");
    switch (res.type) {
      case "0":
        changepwdMsg.value = res.msg;
        break;
      default:
        changepwdMsg.value = res.msg;
        break;
    }
    return res.type;
  }

  // 會員管理-修改信箱
  async function MailUpdate(data: MailFormData) {
    const { sEmail } = data;
    const m_user_c = login.CookieMUC();
    const postData = {
      m_user_c,
      sEmail,
    };
    const res: APItypes.MailUpdateResponse = await APIfn.postMailUpdate(
      postData
    );
    // console.log(res, "MailUpdate");
    errorAlert.AddErrorAlert(res.msg);
    switch (res.type) {
      case "0":
        location.reload();
        break;
      default:
        location.reload();
        break;
    }
  }

  // 會員管理-修改Line
  async function LineUpdate(data: LineFormData) {
    const { sLineId } = data;
    const m_user_c = login.CookieMUC();
    const postData = {
      m_user_c,
      sLineId,
    };
    const res: APItypes.MailUpdateResponse = await APIfn.postLineUpdate(
      postData
    );
    // console.log(res, postData, "LineUpdate");
    errorAlert.AddErrorAlert(res.msg);
    switch (res.type) {
      case "0":
        location.reload();
        break;
      default:
        location.reload();
        break;
    }
  }
  //#endregion

  //#region 會員信箱
  const MailBoxPage = ref<number>(1);
  const MailBoxPerPage = ref<number>(10); //單頁筆數
  const MailBox = ref<APItypes.MailBoxDataList[]>([]);
  const TotalMailBoxPage = computed(() => {
    return Math.ceil(MailBox.value.length / MailBoxPerPage.value);
  });
  const CurrentMailBox = computed(() => {
    const start = (MailBoxPage.value - 1) * MailBoxPerPage.value;
    const end = start + MailBoxPerPage.value;
    return MailBox.value.slice(start, end);
  });
  async function toMemberMail() {
    loadingPage.openLoadingPage();
    await CheckLogin(successMailBox, failMailBox);
  }

  const defaultMailBoxData = ref<APItypes.MailBoxData>({
    nReadStatus: -1,
    sStartTime: timeListLogic.getDataDate(timeListLogic.getTodayDay()),
    sEndTime: timeListLogic.getDataDate(EndDay()),
    m_user_c: "",
  });
  const postMailBoxData = computed(() => {
    const { nReadStatus, sStartTime, sEndTime } = defaultMailBoxData.value;
    return {
      nReadStatus,
      sStartTime: sStartTime + " " + login.forStartTime,
      sEndTime: sEndTime + " " + login.forEndTime,
      m_user_c: "",
    };
  });

  // 取本月資料
  function getThisMonthMail() {
    const today = new Date();
    const day = today.getDay();
    const defaultEndDay = timeListLogic.getCashTypeEndTimeDay(day);
    defaultMailBoxData.value.sStartTime = timeListLogic.getDataDate(day - 29);
    defaultMailBoxData.value.sEndTime =
      timeListLogic.getDataDate(defaultEndDay);
    getMailBoxData();
  }

  async function successMailBox() {
    // console.log("successMailBox");
    // await getMailBoxData(data);
    await getThisMonthMail();
    loadingPage.closeLoadingPage();
    return;
  }
  function failMailBox() {
    // console.log("failMailBox");
    router.push({ name: "login" });
    loadingPage.closeLoadingPage();
    return;
  }
  // 取得信件
  async function getMailBoxData(
    data: APItypes.MailBoxData = postMailBoxData.value
  ) {
    const res = await APIfn.postMailBox({
      ...data,
      m_user_c: login.CookieMUC(),
    });
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    // console.log(res, data, "getMailBoxData");
    switch (res.type) {
      case "0":
        MailBox.value = Object.values(res.dataList);
        break;
      case "1":
        // errorAlert.AddErrorAlert(res.msg);
        MailBox.value = [];
        break;
      default:
        errorAlert.AddErrorAlert(res.msg);
        MailBox.value = [];
        break;
    }
    MailBoxPage.value = 1;
  }

  // 跳轉頁數
  async function goMailBoxPage(page: number) {
    await getMailBoxData(postMailBoxData.value);
    MailBoxPage.value = page;
  }

  // 刪除已讀訊息
  async function DeleteHaveReadMail() {
    const m_user_c = login.CookieMUC();

    const HaveReads = MailBox.value.filter((item) => {
      return item.nRead === "1";
    });
    const HaveReadsNId = HaveReads.map((item) => {
      return item.nId;
    }).join();

    const postData = {
      nId: HaveReadsNId,
      m_user_c,
    };
    const res = await APIfn.postMailDelete(postData);
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    // console.log(res, "DeleteHaveReadMail");
    switch (res.type) {
      case "0":
        getMailBoxData(postMailBoxData.value);
        getMailCount();
        errorAlert.AddErrorAlert(res.msg);
        break;
      case undefined:
        getMailBoxData(postMailBoxData.value);
        errorAlert.AddErrorAlert(t("Store.MailBox.NoUnReadMail"));
        break;
      default:
        getMailBoxData(postMailBoxData.value);
        errorAlert.AddErrorAlert(res.msg);
        break;
    }
  }

  // 一鍵讀取
  async function ReadAllMail() {
    const m_user_c = login.CookieMUC();
    // 沒有信件時
    if (MailBox.value.length === 0) {
      store.hiddenAllAlert();
      store.hiddenAlertBg();
      return errorAlert.AddErrorAlert(t("Store.MailBox.NoMail"));
    }

    const HaveReads = MailBox.value.filter((item) => {
      return item.nRead === "0";
    });
    const HaveReadsNId = HaveReads.map((item) => {
      return item.nId;
    }).join();

    // 沒有未讀信件時
    if (HaveReadsNId.length === 0) {
      store.hiddenAllAlert();
      store.hiddenAlertBg();
      return errorAlert.AddErrorAlert(t("Store.MailBox.HasNoUnReadMail"));
    }
    const postData = {
      nId: HaveReadsNId,
      m_user_c,
    };
    const res = await APIfn.postMailRead(postData);
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    switch (res.type) {
      case "0":
        errorAlert.AddErrorAlert(t("Store.MailBox.OperationCompleted"));
        getMailBoxData(postMailBoxData.value);
        getMailCount();
        break;
      default:
        errorAlert.AddErrorAlert(res.msg);
        getMailBoxData(postMailBoxData.value);
        break;
    }
  }

  // 讀取信件
  async function ReadMail(id: string) {
    const hasRead =
      MailBox.value.find((item) => {
        return item.nId === id;
      })?.nRead === "1";
    if (hasRead) return;
    const m_user_c = login.CookieMUC();
    const postData = {
      nId: id,
      m_user_c,
    };
    const res = await APIfn.postMailRead(postData);
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    switch (res.type) {
      case "0":
        getMailBoxData(postMailBoxData.value);
        getMailCount();
        break;
      default:
        errorAlert.AddErrorAlert(res.msg);
        getMailBoxData(postMailBoxData.value);
        break;
    }
  }

  // 信件內容
  const MailBoxAlertContent = ref<APItypes.MailBoxDataList>(
    {} as APItypes.MailBoxDataList
  );
  function getMailBoxAlertContent(Id: string) {
    const foundRecord = MailBox.value.find((item) => {
      return item.nId === Id;
    });

    if (foundRecord) {
      MailBoxAlertContent.value = foundRecord;
    } else {
      MailBoxAlertContent.value = {} as APItypes.MailBoxDataList;
    }
  }

  // 刪除信件
  async function deleteMailBoxAlert(Id: string) {
    const m_user_c = login.CookieMUC();
    const postData = {
      nId: Id,
      m_user_c,
    };
    const res = await APIfn.postMailDelete(postData);
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    // console.log(res, "DeleteHaveReadMail");
    switch (res.type) {
      case "0":
        getMailBoxData(postMailBoxData.value);
        errorAlert.AddErrorAlert(res.msg);
        break;
      default:
        getMailBoxData(postMailBoxData.value);
        errorAlert.AddErrorAlert(res.msg);
        break;
    }
  }

  // 刪除信件彈窗 內容
  const DeleteAlertId = ref<string>("");
  function getDeleteAlertId(Id: string) {
    if (Id === "all") {
      if (MailBox.value.length === 0) {
        store.hiddenAllAlert();
        store.hiddenAlertBg();
        return errorAlert.AddErrorAlert(t("Store.MailBox.NoMail"));
      }

      const HaveReads = MailBox.value.filter((item) => {
        return item.nRead === "1";
      });
      const HaveReadsNId = HaveReads.map((item) => {
        return item.nId;
      }).join();

      // 沒有已讀信件時
      if (HaveReadsNId.length === 0) {
        store.hiddenAllAlert();
        store.hiddenAlertBg();
        return errorAlert.AddErrorAlert(t("Store.MailBox.HasNoReadMail"));
      }
    }
    DeleteAlertId.value = Id;
  }
  //#endregion

  //#region 客服訊息

  // web版 需要
  const isService = ref<boolean>(false);
  function openService() {
    isService.value = true;
  }
  function closeService() {
    isService.value = false;
  }

  const isShowService = ref<boolean>(false);
  function ToggleService(isOpen: boolean) {
    isService.value = isOpen;
    isShowService.value = !isShowService.value;
  }
  watch(isShowService, (n) => {
    if (n) {
      toService();
      // console.log(isShowService.value, "isShowService.value");
    } else {
      stopTimeoutToGetWebTalk();
      // console.log(isShowService.value, "isShowService.value");
    }
  });
  function toService() {
    CheckLogin(successService, failService);
  }

  function successService() {
    startTimeoutToGetWebTalk();
    return;
  }
  function failService() {
    // stopTimeoutToGetWebTalk();
    startTimeoutToGetWebTalk();
    return;
  }

  // 每三秒接一次訊息
  let webTalkTime = false;
  let webTalkTimer: number | null = null;
  function TimeoutToGetWebTalk(nFstIn: number = 0) {
    if (!webTalkTime) return;
    webTalkTimer = setTimeout(() => {
      getWebTalk(nFstIn);
      TimeoutToGetWebTalk();
    }, 3000);
  }
  function stopTimeoutToGetWebTalk() {
    webTalkTime = false;
    if (webTalkTimer !== null) {
      clearTimeout(webTalkTimer);
      webTalkTimer = null;
    }
  }
  function startTimeoutToGetWebTalk() {
    webTalkTime = true;
    if (webTalkTimer === null) {
      TimeoutToGetWebTalk(1);
    }
  }

  const WebTalkMsg = ref<string>("");
  const WebTalk = ref<{ [key: string]: string }>(
    {} as { [key: string]: string }
  );
  async function getWebTalk(nFstIn: number = 0) {
    const m_user_c = login.CookieMUC();
    const sLang = languages.LanguageType;
    const postData: APItypes.WebTalkReadData = {
      sLang,
      m_user_c,
      nFstIn,
    };
    const res = await APIfn.postWebTalk(postData);
    // console.log(res, postData, "getWebTalk");
    switch (res.type) {
      case "0":
        WebTalk.value = res.dataList;
        break;
      default:
        stopTimeoutToGetWebTalk();
        WebTalkMsg.value = t("Store.Service.WebTalkInfoError");
        WebTalk.value = res.dataList;
        break;
    }
  }

  //發送訊息
  async function sendWebTalk(sendText: string, aFile: Blob | string) {
    const m_user_c = login.CookieMUC();
    const sLang = languages.LanguageType;
    const postData = new FormData();
    postData.append("sLang", sLang);
    postData.append("m_user_c", m_user_c);
    if (sendText) {
      postData.append("sendText", sendText);
    }
    if (aFile) {
      postData.append("aFile", aFile);
    }

    const res = await APIfn.postWebTalkSend(postData);
    // console.log(res, aFile, sendText, m_user_c, "sendWebTalk");
    switch (res.type) {
      case "0":
        WebTalk.value = res.dataList;
        return;
      default:
        stopTimeoutToGetWebTalk();
        WebTalk.value = res.dataList;
        break;
    }
    switch (res.nTypeImg) {
      case 0:
      case -1:
        WebTalk.value = res.dataList;
        return;
      default:
        stopTimeoutToGetWebTalk();
        errorAlert.AddErrorAlert(res.sMsgImg);
        WebTalk.value = res.dataList;
        break;
    }
  }

  // 取得Line
  async function getWebTalkLineService() {
    const res = await APIfn.GetWebTalkLineService();
    const lineLink = res.sLink;
    document.location = lineLink;
    // window.open(lineLink, "_self");
  }

  //#endregion

  //#region 交易紀錄
  const TradeRecordPage = ref<number>(1);
  const TradeRecordPerPage = ref<number>(10); //單頁筆數
  const TradeRecord = ref<APItypes.TransactionRecordList[]>([]);
  const TotalTradeRecordPage = computed(() => {
    return Math.ceil(TradeRecord.value.length / TradeRecordPerPage.value);
  });
  const CurrentTradeRecord = computed(() => {
    const start = (TradeRecordPage.value - 1) * TradeRecordPerPage.value;
    const end = start + TradeRecordPerPage.value;
    return TradeRecord.value.slice(start, end);
  });
  async function toTradeRecord() {
    if (!login.isCashMember) return router.push({ name: "main" });
    loadingPage.openLoadingPage();
    await CheckLogin(successTradeRecord, failTradeRecord);
  }

  const defaultTransactionRecord = ref<APItypes.TransactionRecordData>({
    nType: -1,
    sStartTime: timeListLogic.getDataDate(timeListLogic.getTodayDay()),
    sEndTime: timeListLogic.getDataDate(EndDay()),
    m_user_c: "",
  });
  const postTransactionRecordData = computed(() => {
    const { sStartTime, sEndTime, nType } = defaultTransactionRecord.value;
    // console.log(login.CookieMUC(), "postTransactionRecordData");
    return {
      nType,
      sStartTime: sStartTime + " " + login.forStartTime,
      sEndTime: sEndTime + " " + login.forEndTime,
      m_user_c: "",
    };
  });

  // 抓取今日的紀錄
  function getTodayTradeRecord() {
    defaultTransactionRecord.value.sStartTime = timeListLogic.getDataDate(
      timeListLogic.getTodayDay()
    );
    defaultTransactionRecord.value.sEndTime = timeListLogic.getDataDate(
      EndDay()
    );

    getTradeRecordData();
  }

  async function successTradeRecord(
    data: APItypes.TransactionRecordData = postTransactionRecordData.value
  ) {
    // console.log(data, login.CookieMUC(), "successTradeRecord");
    await getTodayTradeRecord();
    loadingPage.closeLoadingPage();
    return;
  }
  async function failTradeRecord() {
    // console.log("failTradeRecord", router);
    router.push({ name: "login" });

    loadingPage.closeLoadingPage();
    return;
  }
  async function getTradeRecordData(
    data: APItypes.TransactionRecordData = postTransactionRecordData.value
  ) {
    const res = await APIfn.postTransactionRecord({
      ...data,
      m_user_c: login.CookieMUC(),
    });
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    // console.log(data, "getTradeRecordData");
    // console.log(res, "getTradeRecordData");
    switch (res.type) {
      case "0":
        TradeRecord.value = res.dataList;
        break;
      case "1":
        TradeRecord.value = [];
        break;
      default:
        TradeRecord.value = [];
        errorAlert.AddErrorAlert(res.msg);
        break;
    }
    TradeRecordPage.value = 1;
  }

  // 換頁
  async function goTradeRecordPage(page: number) {
    // await getTradeRecordData(postTransactionRecordData.value);
    // console.log(page);
    TradeRecordPage.value = page;
  }
  // 交易詳細記錄
  const TradeRecordAlertContent = ref<APItypes.TransactionRecordList>(
    {} as APItypes.TransactionRecordList
  );
  function getTradeRecordAlertContent(nId: string) {
    const foundRecord = TradeRecord.value.find((item) => {
      return item.nId === nId;
    });

    if (foundRecord) {
      TradeRecordAlertContent.value = foundRecord;
      // console.log(TradeRecordAlertContent.value);
    } else {
      TradeRecordAlertContent.value = {} as APItypes.TransactionRecordList;
    }
  }

  //#endregion

  //#region 投注紀錄
  const BetRecordPage = ref<number>(1);
  const BetRecordPerPage = ref<number>(10); //單頁筆數
  const BetRecord = ref<APItypes.BetDetailList[]>([]);
  const TotalBetRecordPage = computed(() => {
    return Math.ceil(BetRecord.value.length / BetRecordPerPage.value);
  });
  const CurrentBetRecord = computed(() => {
    const start = (BetRecordPage.value - 1) * BetRecordPerPage.value;
    const end = start + BetRecordPerPage.value;
    return BetRecord.value.slice(start, end);
  });
  async function toBetRecord() {
    loadingPage.openLoadingPage();
    await CheckLogin(successBetRecord, failBetRecord);
  }

  const defaultBetRecordData = ref<APItypes.BetDetailData>({
    sLang: languages.LanguageType,
    sStartTime: timeListLogic.getDataDate(timeListLogic.getTodayDay()),
    sEndTime: timeListLogic.getDataDate(EndDay()),
    m_user_c: "",
  });
  const postBetRecordData = computed(() => {
    const sLang = languages.LanguageType;
    const { sStartTime, sEndTime } = defaultBetRecordData.value;
    return {
      sLang,
      sStartTime: sStartTime + " " + login.forStartTime,
      sEndTime: sEndTime + " " + login.forEndTime,
      m_user_c: "",
    };
  });

  // 抓取今日的紀錄
  async function getTodayBetRecord() {
    defaultBetRecordData.value.sStartTime = timeListLogic.getDataDate(
      timeListLogic.getTodayDay()
    );
    defaultBetRecordData.value.sEndTime = timeListLogic.getDataDate(EndDay());
    await getBetRecordData();
  }

  async function successBetRecord() {
    // console.log("successBetRecord");
    getTodayBetRecord();
    await getTodayBetStatistics();
    loadingPage.closeLoadingPage();
    return;
  }
  function failBetRecord() {
    // console.log("failBetRecord");
    router.push({ name: "login" });
    loadingPage.closeLoadingPage();
    return;
  }
  // 取得投注紀錄
  async function getBetRecordData(
    data: APItypes.BetDetailData = postBetRecordData.value
  ) {
    loadingPage.openLoadingPage();
    const res = await APIfn.postBetDetail({
      ...data,
      m_user_c: login.CookieMUC(),
    });
    loadingPage.closeLoadingPage();
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    // console.log(data, "getBetRecordData");
    // console.log(res, "getBetRecordData");
    switch (res.type) {
      case "0":
        BetRecord.value = Object.values(res.dataList);
        break;
      case "1":
        BetRecord.value = [];
        // errorAlert.AddErrorAlert(res.msg);
        break;
      default:
        BetRecord.value = [];
        errorAlert.AddErrorAlert(res.msg);
        break;
    }
    BetRecordPage.value = 1;
  }

  // 換頁
  async function goBetRecordPage(page: number) {
    // console.log(page);
    await getBetRecordData(postBetRecordData.value);
    BetRecordPage.value = page;
  }
  // 交易詳細記錄
  const BetRecordAlertContent = ref<APItypes.BetDetailList>(
    {} as APItypes.BetDetailList
  );
  function getBetRecordAlertContent(Id: string) {
    const foundRecord = BetRecord.value.find((item) => {
      return item["1"] === Id;
    });

    if (foundRecord) {
      BetRecordAlertContent.value = foundRecord;
    } else {
      BetRecordAlertContent.value = {} as APItypes.BetDetailList;
    }
  }
  //#endregion

  //#region 投注報表
  const BetStatisticsAGame = ref<APItypes.BetStatisticsAGame>({});
  const BetStatisticsASum = ref<APItypes.BetStatisticsASum>(
    {} as APItypes.BetStatisticsASum
  );
  const defaultBetStatisticsData = ref<APItypes.BetStatisticsData>({
    sStartTime: timeListLogic.getDataDate(timeListLogic.getTodayDay()),
    sEndTime: timeListLogic.getDataDate(EndDay()),
    m_user_c: "",
  });
  const postBetStatisticsData = computed(() => {
    const { sStartTime, sEndTime } = defaultBetStatisticsData.value;
    return {
      sStartTime: sStartTime + " " + login.forStartTime,
      sEndTime: sEndTime + " " + login.forEndTime,
      m_user_c: "",
    };
  });

  // 抓取今日的紀錄
  async function getTodayBetStatistics() {
    defaultBetStatisticsData.value.sStartTime = timeListLogic.getDataDate(
      timeListLogic.getTodayDay()
    );
    defaultBetStatisticsData.value.sEndTime = timeListLogic.getDataDate(
      EndDay()
    );
    await getBetStatisticsData();
  }
  // 取得投注紀錄
  async function getBetStatisticsData(
    data: APItypes.BetStatisticsData = postBetStatisticsData.value
  ) {
    loadingPage.openLoadingPage();
    const res = await APIfn.postBetStatistics({
      ...data,
      m_user_c: login.CookieMUC(),
    });
    loadingPage.closeLoadingPage();
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    // console.log(data, "getBetStatisticsData");
    console.log(res, "getBetStatisticsData");
    switch (res.type) {
      case "0":
        BetStatisticsAGame.value = res.dataList.aGame;
        BetStatisticsASum.value = res.dataList.aSum;
        break;
      case "1":
        BetStatisticsAGame.value = {};
        BetStatisticsASum.value = {} as APItypes.BetStatisticsASum;
        // errorAlert.AddErrorAlert(res.msg);
        break;
      default:
        BetStatisticsAGame.value = {};
        BetStatisticsASum.value = {} as APItypes.BetStatisticsASum;
        errorAlert.AddErrorAlert(res.msg);
        break;
    }
    BetRecordPage.value = 1;
  }
  //#endregion

  //#region 外接館餘額
  const ApiBalance = ref<APItypes.ApiBalanceList>(
    {} as APItypes.ApiBalanceList
  );
  function priorityFn(name: string) {
    const rank = name.includes("真人")
      ? 1
      : name.includes("電子")
      ? 2
      : name.includes("RSG")
      ? 2
      : name.includes("體育")
      ? 3
      : name.includes("鑫寶")
      ? 3
      : name.includes("棋牌")
      ? 4
      : name.includes("ZG")
      ? 5
      : name.includes("捕魚")
      ? 5
      : name.includes("愛發")
      ? 5
      : name.includes("彩票")
      ? 6
      : name.includes("GR")
      ? 6
      : 0;
    return rank;
  }
  const SortApiBalance = computed(() => {
    const sort = Object.keys(ApiBalance.value)
      .map((key) => [key, ApiBalance.value[key]])
      .sort((a, b) => {
        console.log(a[0], b[0]);
        return priorityFn(a[0].toString()) - priorityFn(b[0].toString());
      });
    return sort;
  });
  async function toApiBalance() {
    loadingPage.openLoadingPage();
    await CheckLogin(successApiBalance, failApiBalance);
  }
  async function successApiBalance() {
    // console.log("successBetRecord");
    await getApiBalanceData();

    loadingPage.closeLoadingPage();
    return;
  }
  function failApiBalance() {
    // console.log("failApiBalance");
    router.push({ name: "login" });

    loadingPage.closeLoadingPage();
    return;
  }

  // 取得餘額
  async function getApiBalanceData() {
    const sLang = languages.LanguageType;
    const postData = {
      sLang,
      m_user_c: login.CookieMUC(),
    };
    const res = await APIfn.postApiBalance(postData);
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    // console.log(res, "getApiBalanceData");
    switch (res.type) {
      case "0":
        ApiBalance.value = res.dataList;
        break;
      default:
        ApiBalance.value = {};
        errorAlert.AddErrorAlert(res.msg);
        break;
    }
  }

  // 一鍵轉回
  const isAllMoneyBacking = ref<boolean>(false);
  async function getAllMoneyBackData(sGame: string) {
    if (isAllMoneyBacking.value) return;
    const postData = {
      sGame,
      m_user_c: login.CookieMUC(),
    };
    isAllMoneyBacking.value = true;
    const res = await APIfn.postAllMoneyBack(postData);
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    // console.log(res, "getAllMoneyBackData");
    switch (res.type) {
      case "0":
        errorAlert.AddErrorAlert(t("Store.ApiBalance.BackSuccessful"));
        await getApiBalanceData();
        break;
      default:
        errorAlert.AddErrorAlert(res.msg);
        break;
    }
    isAllMoneyBacking.value = false;
  }

  //#endregion

  //#region VIP等級
  const VipList = ref<APItypes.VipListList[]>([]);
  const VipListSort = computed(() => {
    const listSort = VipList.value.sort((item1, item2) => {
      return parseInt(item1.nVipLv) - parseInt(item2.nVipLv);
    });
    return listSort;
  });
  const VipListMaxLv = computed(() => {
    return VipListSort.value[VipListSort.value.length - 1];
  });
  async function toVipPrivilege() {
    if (!login.isCashMember) return router.push({ name: "main" });
    loadingPage.openLoadingPage();
    await CheckLogin(successVipPrivilege, failVipPrivilege);
  }
  async function successVipPrivilege() {
    // console.log("successVipPrivilege");
    await getVipListData();

    loadingPage.closeLoadingPage();
    return;
  }
  function failVipPrivilege() {
    // console.log("failVipPrivilege");
    router.push({ name: "login" });

    loadingPage.closeLoadingPage();
    return;
  }
  async function getVipListData() {
    const m_user_c = login.CookieMUC();
    const postData = {
      m_user_c,
    };
    const res = await APIfn.postVipList(postData);
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    // console.log(res, "getVipListData");
    switch (res.type) {
      case "0":
        VipList.value = res.dataList;
        break;
      default:
        break;
    }
  }
  //#endregion

  //#region 最新消息
  const NewMes = ref<APItypes.NewMessageResponseList[]>([]);
  const NewJumpMes = computed(() => {
    return NewMes.value.filter((item) => {
      return item.nJump === "1";
    });
  });
  const selectNewJumpId = ref<string>("");
  const NewJumpContent = computed(() => {
    const findNewJumpContent = NewJumpMes.value.find((item) => {
      return item.nId === selectNewJumpId.value;
    });
    return findNewJumpContent
      ? findNewJumpContent
      : ({} as APItypes.NewMessageResponseList);
  });
  async function toNewMessage() {
    loadingPage.openLoadingPage();
    await CheckLogin(successNewMessage, failVipNewMessage);
  }
  async function successNewMessage() {
    // console.log("successNewMessage");
    await getNewMessageData();

    loadingPage.closeLoadingPage();
    return;
  }
  async function failVipNewMessage() {
    // console.log("failVipNewMessage");
    await getNewMessageData();

    loadingPage.closeLoadingPage();
    return;
  }
  async function getNewMessageData() {
    const m_user_c = login.CookieMUC();
    const getData = {
      m_user_c,
      nLang: languages.LanguageType,
    };
    const res = await APIfn.postNewMessage(getData);
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    // console.log(res, "getNewMessageData");
    switch (res.type) {
      case "0":
        NewMes.value = res.dataList;
        break;
    }
  }

  function selectNewsContentAlert(id: string) {
    selectNewJumpId.value = id;
  }
  //#endregion

  //#region 優惠
  async function toDiscountPage() {
    if (!login.isCashMember && login.isLogin)
      return router.push({ name: "main" });
    loadingPage.openLoadingPage();
    await CheckLogin(successDiscountPage, failDiscountPage);
  }

  async function successDiscountPage() {
    loadingPage.closeLoadingPage();
    return;
  }
  function failDiscountPage() {
    // router.push({ name: "login" });
    loadingPage.closeLoadingPage();
    return;
  }
  //#endregion

  //#region 分享發財
  interface ShareDataType {
    sUrl: string;
    sQrImg: string;
    sQrImg2: string;
  }
  const ShareData = ref<ShareDataType>({} as ShareDataType);
  async function toShareMakeFortunePage() {
    if (!login.isCashMember) return router.push({ name: "main" });
    loadingPage.openLoadingPage();
    await CheckLogin(successShareMakeFortunePage, failShareMakeFortunePage);
  }

  async function successShareMakeFortunePage() {
    // console.log("successShareMakeFortunePage");
    await getShareMakeData();

    loadingPage.closeLoadingPage();
    return;
  }
  function failShareMakeFortunePage() {
    // console.log("failMemberAccount");
    router.push({ name: "login" });

    loadingPage.closeLoadingPage();
    return;
  }
  async function getShareMakeData() {
    const m_user_c = login.CookieMUC();
    const getData = {
      m_user_c,
    };
    const res = await APIfn.postSharePage(getData);
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    // console.log(res, "getShareMakeData");
    switch (res.type) {
      case 1:
        ShareData.value = res.aShareData;
        break;
    }
  }

  // 分享紀錄
  const ShareList = ref<APItypes.ShareListAShareList>(
    {} as APItypes.ShareListAShareList
  );
  const ShareListCount = ref<number>(0);

  function toShareRecord() {
    CheckLogin(successShareRecord, failShareRecord);
  }
  function successShareRecord() {
    // console.log("successShareRecord");
    getShareRecord();
    return;
  }
  function failShareRecord() {
    // console.log("failShareRecord");
    router.push({ name: "login" });
    return;
  }
  async function getShareRecord() {
    const m_user_c = login.CookieMUC();
    const getData = {
      m_user_c,
    };
    const res = await APIfn.postShareList(getData);
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    // console.log(res, "getShareRecord");
    switch (res.type) {
      case 1:
        ShareList.value = res.aShareList;
        ShareListCount.value = res.nCount;
        break;
      default:
        ShareList.value = {};
        ShareListCount.value = 0;
        break;
    }
    // console.log(ShareList.value, "getShareRecord");
  }

  // 分享回饋
  const ShareFsLogPage = ref<number>(1);
  const ShareFsLogPerPage = ref<number>(10); //單頁筆數
  const ShareFsLog = ref<APItypes.ShareFsLogAShareFsLog[]>([]);
  const TotalShareFsLogPage = computed(() => {
    return Math.ceil(ShareFsLog.value.length / ShareFsLogPerPage.value);
  });
  const CurrentShareFsLog = computed(() => {
    const start = (ShareFsLogPage.value - 1) * ShareFsLogPerPage.value;
    const end = start + ShareFsLogPerPage.value;
    return ShareFsLog.value.slice(start, end);
  });
  const ShareFsLogCount = ref<number>(0);

  const defaultShareFsLog = ref<APItypes.ShareFsLogData>({
    sStartTime: timeListLogic.getDataDate(timeListLogic.getTodayDay()),
    sEndTime: timeListLogic.getDataDate(EndDay()),
    m_user_c: "",
  });
  const postShareFsLogData = computed(() => {
    const { sStartTime, sEndTime } = defaultShareFsLog.value;
    // console.log(login.CookieMUC(), "postShareFsLogData");
    return {
      sStartTime: sStartTime + " " + login.forStartTime,
      sEndTime: sEndTime + " " + login.forEndTime,
      m_user_c: "",
    };
  });

  // 取今日
  function getTodayShareFsLog() {
    defaultShareFsLog.value.sStartTime = timeListLogic.getDataDate(
      timeListLogic.getTodayDay()
    );
    defaultShareFsLog.value.sEndTime = timeListLogic.getDataDate(EndDay());
    getShareFsLog();
  }

  function toShareFsLog() {
    CheckLogin(successShareFsLog, failShareFsLog);
  }
  function successShareFsLog() {
    getTodayShareFsLog();
    return;
  }
  function failShareFsLog() {
    // console.log("failShareFsLog");
    router.push({ name: "login" });
    return;
  }
  async function getShareFsLog(
    data: APItypes.ShareFsLogData = postShareFsLogData.value
  ) {
    const res = await APIfn.postShareFsLog({
      ...data,
      m_user_c: login.CookieMUC(),
    });
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    // console.log(res, "getShareFsLog");
    switch (res.type) {
      case 1:
        ShareFsLog.value = Object.values(res.aShareFsLog);
        ShareFsLogCount.value = res.nCount;
        break;
      default:
        ShareFsLog.value = [];
        ShareFsLogCount.value = 0;
        break;
    }
  }

  // 換頁
  async function goShareFsLogPage(page: number) {
    // console.log(page);
    ShareFsLogPage.value = page;
  }

  //#endregion

  // 代理
  //#region 會員列表
  const UserListPage = ref<number>(1);
  const UserListPerPage = ref<number>(10); //單頁筆數
  const UserList = ref<APItypes.UserListList[]>([]);
  const TotalUserListPage = computed(() => {
    return Math.ceil(UserList.value.length / UserListPerPage.value);
  });
  const CurrentUserList = computed(() => {
    const start = (UserListPage.value - 1) * UserListPerPage.value;
    const end = start + UserListPerPage.value;
    return UserList.value.slice(start, end);
  });
  async function toUserList() {
    loadingPage.openLoadingPage();
    await CheckLogin(successUserList, failUserList);
  }

  const defaultUserListData = () => {
    return {
      m_user_c: login.CookieMUC(),
    };
  };

  async function successUserList(
    data: APItypes.UserListData = defaultUserListData()
  ) {
    // console.log("successUserList");
    const myRank = login.memberData.nRank;
    if (myRank === "0" || myRank === "") {
      // 不是代理的話
      router.push({ name: "main" });
    } else {
      await getUserListData(data);
    }

    loadingPage.closeLoadingPage();
    return;
  }
  function failUserList() {
    // console.log("failUserList");
    router.push({ name: "login" });

    loadingPage.closeLoadingPage();
    return;
  }

  // 取得列表
  async function getUserListData(
    data: APItypes.UserListData = defaultUserListData()
  ) {
    const res = await APIfn.postUserList(data);
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    // console.log(res, "getUserListData");
    switch (res.type) {
      case "0":
        UserList.value = res.dataList;
        break;
      case "1":
        UserList.value = [];
        // errorAlert.AddErrorAlert(res.msg);
        break;
      default:
        UserList.value = [];
        errorAlert.AddErrorAlert(res.msg);
        break;
    }
    UserListPage.value = 1;
  }

  // 換頁
  async function goUserListPage(page: number, data: APItypes.UserListData) {
    await getUserListData(data);
    UserListPage.value = page;
  }
  // 會員詳細記錄
  const UserListAlertContent = ref<APItypes.UserListList>(
    {} as APItypes.UserListList
  );
  function getUserListAlertContent(Id: string) {
    const foundRecord = UserList.value.find((item) => {
      return item.nId === Id;
    });

    if (foundRecord) {
      UserListAlertContent.value = foundRecord;
    } else {
      UserListAlertContent.value = {} as APItypes.UserListList;
    }
  }
  //#endregion

  //#region 推廣
  const PromotionPage = ref<number>(1);
  const PromotionPerPage = ref<number>(10); //單頁筆數
  const Promotion = ref<APItypes.PromotionList[]>([]);
  const TotalPromotionPage = computed(() => {
    return Math.ceil(Promotion.value.length / PromotionPerPage.value);
  });
  const CurrentPromotion = computed(() => {
    const start = (PromotionPage.value - 1) * PromotionPerPage.value;
    const end = start + PromotionPerPage.value;
    return Promotion.value.slice(start, end);
  });
  async function toPromotion() {
    if (!login.isCashMember) return router.push({ name: "main" });
    loadingPage.openLoadingPage();
    await CheckLogin(successPromotion, failPromotion);
  }

  const defaultPromotionData = () => {
    return {
      m_user_c: login.CookieMUC(),
    };
  };

  async function successPromotion(
    data: APItypes.PromotionData = defaultPromotionData()
  ) {
    // console.log("successPromotion");
    const myRank = login.memberData.nRank;
    if (myRank === "0" || myRank === "") {
      // 不是代理的話
      router.push({ name: "MemberManagement" });
      errorAlert.AddErrorAlert(t("Store.InsufficientPermissions"));
    } else {
      await getPromotionData(data);
    }

    loadingPage.closeLoadingPage();
    return;
  }
  function failPromotion() {
    // console.log("failPromotion");
    router.push({ name: "login" });

    loadingPage.closeLoadingPage();
    return;
  }
  // 取得列表
  async function getPromotionData(
    data: APItypes.PromotionData = defaultPromotionData()
  ) {
    const res = await APIfn.postPromotion(data);
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    // console.log(res, "getPromotionData");
    switch (res.type) {
      case "0":
        Promotion.value = Object.values(res.dataList);
        break;
      case "1":
        Promotion.value = [];
        // errorAlert.AddErrorAlert(res.msg);
        break;
      default:
        Promotion.value = [];
        errorAlert.AddErrorAlert(res.msg);
        break;
    }
    PromotionPage.value = 1;
  }

  // 換頁
  async function goPromotionPage(page: number) {
    // await getPromotionData();
    PromotionPage.value = page;
  }
  // 推廣詳細記錄
  const PromotionAlertContent = ref<APItypes.PromotionList>(
    {} as APItypes.PromotionList
  );
  function getPromotionAlertContent(Id: string) {
    const foundRecord = Promotion.value.find((item) => {
      return item.nId === Id;
    });

    if (foundRecord) {
      PromotionAlertContent.value = foundRecord;
    } else {
      PromotionAlertContent.value = {} as APItypes.PromotionList;
    }
  }

  // 刪除推廣
  const deletePromotionId = ref<string>("");
  function getDeletePromotionId(Id: string) {
    deletePromotionId.value = Id;
  }
  async function deletePromotion() {
    const m_user_c = login.CookieMUC();
    const nPid = deletePromotionId.value;
    const postData = {
      m_user_c,
      nPid,
    };
    const res = await APIfn.postPromotionDelete(postData);
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    // console.log(postData, "deletePromotion");
    switch (res.type) {
      case "0":
        getDeletePromotionId("");
        break;
      default:
        errorAlert.AddErrorAlert(res.msg);
        break;
    }
    getPromotionData();
    store.hiddenAllAlert();
    store.hiddenAlertBg();
  }

  // 新增推廣
  async function addPromotion(data: AddPromotionFormData) {
    // console.log("addPromotion", data);
    const m_user_c = login.CookieMUC();
    const postData = {
      m_user_c,
      ...data,
    };
    const res = await APIfn.postPromotionAdd(postData);
    // console.log(res, "addPromotion");
    errorAlert.AddErrorAlert(res.msg);
    switch (res.type) {
      case "0":
        getPromotionData();
        store.hiddenAllAlert();
        store.hiddenAlertBg();
        break;
      default:
        Promotion.value = [];
        break;
    }
  }

  //
  //#endregion

  //#region 轉帳
  const transferPageMsg = ref<string>("");
  async function toTransferPage() {
    transferPageMsg.value = "";
    loadingPage.openLoadingPage();
    await CheckLogin(successTransferPage, failTransferPage);
  }
  function successTransferPage() {
    // console.log("successTransferPage");
    const myRank = login.memberData.nRank;
    transferPageMsg.value = "";
    if (myRank === "0" || myRank === "") {
      // 不是代理的話
      router.push({ name: "MemberManagement" });
      errorAlert.AddErrorAlert(t("Store.InsufficientPermissions"));
    }

    loadingPage.closeLoadingPage();
    return;
  }
  function failTransferPage() {
    // console.log("failTransferPage");
    router.push({ name: "login" });

    loadingPage.closeLoadingPage();
    return;
  }

  async function sendTransferPage(data: TransferPageData) {
    const m_user_c = login.CookieMUC();
    const postData = {
      m_user_c,
      ...data,
    };
    const res = await APIfn.postUserListTransfer(postData);
    // console.log(res, postData, "sendTransferPage");
    switch (res.type) {
      case "0":
        transferPageMsg.value = res.msg;
        errorAlert.AddErrorAlert(res.msg);
        router.push({ name: "MemberList" });
        break;
      default:
        errorAlert.AddErrorAlert(res.msg);
        // console.log(res.msg);
        break;
    }
  }

  //#endregion

  // 儲值提領
  //#region 託售
  const CheckPwdBank = ref<string>("");
  const MemberWithdrawPageMsg = ref<string>("");
  const WithdrawPageAlertContent = ref<string>("");
  const MemberWithdrawPage = ref<APItypes.MemberWithdrawalsPageList>(
    {} as APItypes.MemberWithdrawalsPageList
  );
  async function toWithdrawPage() {
    if (!login.isCashMember) return router.push({ name: "main" });
    loadingPage.openLoadingPage();
    await CheckLogin(successWithdraw, failWithdraw);
  }
  async function successWithdraw() {
    // console.log("successWithdraw");
    await getCheckPwdBank();
    await getWithdrawalsData();
    loadingPage.closeLoadingPage();
    return;
  }
  function failWithdraw() {
    // console.log("failWithdraw");
    router.push({ name: "login" });

    loadingPage.closeLoadingPage();
    return;
  }

  // 確認託售資料
  const hasBankCard = ref<boolean>(false);
  async function getCheckPwdBank() {
    const m_user_c = login.CookieMUC();
    const postData = {
      m_user_c,
    };
    hasBankCard.value = false;
    const res = await APIfn.postCheckPwdBank(postData);
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    CheckPwdBank.value = res.type;
    // console.log(res, "getCheckPwdBankData");
    switch (res.type) {
      case "0":
        getMemberWithdrawalsPage();
        hasBankCard.value = true;
        break;
      case "1": // 沒有交易密碼
        await router.push({ name: "ChangePasswordPage" });
        store.toggleAlertBg();
        store.openWithdrawPageAlert();
        WithdrawPageAlertContent.value = res.msg;
        break;
      case "2": // 尚未實名認證
        await router.push({ name: "BankVerified" });
        store.toggleAlertBg();
        store.openWithdrawPageAlert();
        WithdrawPageAlertContent.value = t(
          "Store.Withdraw.VerificationForFirstWithdraw"
        );
        break;
      case "3": // 實名認證審核中
        // await router.push({ name: "MemberAccount" });
        if (window.innerWidth > 768) {
          ToggleService(true);
        } else {
          await router.push({ name: "Service" });
        }
        store.toggleAlertBg();
        store.openWithdrawPageAlert();
        WithdrawPageAlertContent.value =
          res.msg + t("Store.Deposit.VerificationReview");
        break;
      case "4": // 查無銀行卡
      case "5": // 銀行卡審核中
        // await router.push({ name: "MemberBankPage" });
        store.toggleAlertBg();
        store.openWithdrawPageAlert();
        WithdrawPageAlertContent.value = res.msg;
        break;

      default:
        errorAlert.AddErrorAlert(res.msg);
        break;
    }
  }

  // 託售畫面
  async function getMemberWithdrawalsPage() {
    const m_user_c = login.CookieMUC();
    const postData = {
      m_user_c,
    };
    const res = await APIfn.postMemberWithdrawalsPage(postData);
    //@ts-ignore 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    // console.log(res, "getMemberWithdrawalsPage");
    switch (res.type) {
      case "0":
        MemberWithdrawPage.value = res.dataList;
        break;
      case "2":
        await router.push({ name: "MemberAccount" });
        errorAlert.AddErrorAlert(res.msg);
        break;
      default:
        errorAlert.AddErrorAlert(res.msg);
        break;
    }
  }

  // 選擇銀行卡
  const changeBankNid = ref<string>("");
  const changeBankName = ref<string>("");
  const changeBankBranch = ref<string>("");
  async function changeBank(event: any) {
    const target = event.target as HTMLInputElement;
    const bankNid = target.value;
    //如果選到 請選擇卡號就 清空資料停止
    if (bankNid == "") {
      changeBankNid.value = "";
      changeBankName.value = "";
      changeBankBranch.value = "";
      return;
    }
    // 使用数组的find方法查找匹配的银行数据
    const matchedBank = MemberWithdrawPage.value.user_bank.find(
      (bank) => bank.nId === bankNid
    ) as
      | {
          nId: string;
          nUid: string;
          sPayNum: string;
          sPayAddr: string;
          sCreateTime: string;
          sBank: string;
          sBankId: string;
        }
      | undefined;
    changeBankNid.value = matchedBank?.nId ?? "";
    changeBankName.value = matchedBank?.sBank ?? "";
    changeBankBranch.value = matchedBank?.sPayAddr ?? "";
  }

  //刪除銀行卡
  async function deleteMemberBank() {
    if (changeBankNid.value == "") {
      return;
    }
    if (confirm(t("Store.Withdraw.DeleteBankCardTip"))) {
      const m_user_c = login.CookieMUC();
      const postData = {
        m_user_c,
        withdrawal: changeBankNid.value,
      };
      // console.log(postData);

      const res = await APIfn.postDeleteMemberBank(postData);
      // console.log(res, "postDeleteMemberBank");
      errorAlert.AddErrorAlert(res.msg);
      location.reload();
    }
  }

  // 確認託售
  async function sendMemberWithdrawals(data: WithdrawPageFromData) {
    const m_user_c = login.CookieMUC();
    const postData = {
      m_user_c,
      ...data,
    };
    const res = await APIfn.postMemberWithdrawals(postData);
    // console.log(res, "getMemberWithdrawalsPage");
    errorAlert.AddErrorAlert(res.msg);
    // switch (res.type) {
    //   case "0":
    //     MemberWithdrawPageMsg.value = res.msg;
    //   break;
    //   default:
    //     MemberWithdrawPageMsg.value = res.msg;
    //     break;
    // }
  }

  // 託售列表
  const WithdrawalsPage = ref<number>(1);
  const WithdrawalsPerPage = ref<number>(10); //單頁筆數
  const Withdrawals = ref<APItypes.AWithdrawals[]>([]);
  const TotalWithdrawalsPage = computed(() => {
    return Math.ceil(Withdrawals.value.length / WithdrawalsPerPage.value);
  });
  const CurrentWithdrawals = computed(() => {
    const start = (WithdrawalsPage.value - 1) * WithdrawalsPerPage.value;
    const end = start + WithdrawalsPerPage.value;
    return Withdrawals.value.slice(start, end);
  });

  const defaultWithdrawals = ref<APItypes.WithdrawalsData>({
    sStartTime: timeListLogic.getDataDate(timeListLogic.getTodayDay()),
    sEndTime: timeListLogic.getDataDate(EndDay()),
    m_user_c: "",
    nType: "2",
  });
  const postWithdrawalsData = computed(() => {
    const { sStartTime, sEndTime } = defaultWithdrawals.value;
    // console.log(login.CookieMUC(), "postWithdrawalsData");
    return {
      sStartTime: sStartTime + " " + login.forStartTime,
      sEndTime: sEndTime + " " + login.forEndTime,
      m_user_c: login.CookieMUC(),
      nType: "2",
    };
  });

  async function getWithdrawalsData(
    data: APItypes.WithdrawalsData = postWithdrawalsData.value
  ) {
    const res = await APIfn.PostWithdrawals({
      ...data,
      m_user_c: login.CookieMUC(),
    });
    // console.log(data, "getWithdrawalsData");
    // console.log(res, "getWithdrawalsData");
    switch (res.type) {
      case 1:
        console.log(res);
        Withdrawals.value = Object.values(res.aDepositList);
        break;
      default:
        Withdrawals.value = [];
        // errorAlert.AddErrorAlert(res.sMsg);
        break;
    }
    WithdrawalsPage.value = 1;
  }
  // 換頁
  async function goWithdrawalsPage(page: number) {
    WithdrawalsPage.value = page;
  }
  //#endregion

  //#region USDT託售
  const USDTWithdrawPage = ref<APItypes.MemberWithdrawalsUSDTPageList>(
    {} as APItypes.MemberWithdrawalsUSDTPageList
  );
  async function toUSDTWithdrawPage() {
    loadingPage.openLoadingPage();
    await CheckLogin(successUSDTWithdraw, failUSDTWithdraw);
  }
  async function successUSDTWithdraw() {
    // console.log("successUSDTWithdraw");
    await getCheckPwdBankForUSDTWithdraw();

    loadingPage.closeLoadingPage();
    return;
  }
  function failUSDTWithdraw() {
    // console.log("failUSDTWithdraw");
    router.push({ name: "login" });

    loadingPage.closeLoadingPage();
    return;
  }

  // 確認USDT託售資料
  async function getCheckPwdBankForUSDTWithdraw() {
    const m_user_c = login.CookieMUC();
    const postData = {
      m_user_c,
    };
    const res = await APIfn.postCheckPwdBank(postData);
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    CheckPwdBank.value = res.type;
    // console.log(res, "getCheckPwdBankData");
    switch (res.type) {
      case "0":
      case "4": // 查無銀行卡
      case "5": // 銀行卡審核中
        getUSDTWithdraw();
        break;
      case "1": // 沒有交易密碼
        await router.push({ name: "ChangePasswordPage" });
        store.toggleAlertBg();
        store.openWithdrawPageAlert();
        WithdrawPageAlertContent.value = res.msg;
        break;
      case "2": // 尚未實名認證
        await router.push({ name: "BankVerified" });
        store.toggleAlertBg();
        store.openWithdrawPageAlert();
        WithdrawPageAlertContent.value = t(
          "Store.Withdraw.VerificationForFirstWithdraw"
        );
        break;
      case "3": // 實名認證審核中
        if (window.innerWidth > 768) {
          ToggleService(true);
        } else {
          await router.push({ name: "Service" });
        }
        store.toggleAlertBg();
        store.openWithdrawPageAlert();
        WithdrawPageAlertContent.value =
          res.msg + t("Store.Deposit.VerificationReview");
        break;

      default:
        errorAlert.AddErrorAlert(res.msg);
        break;
    }
  }

  // USDT託售畫面
  const hasUSDT = ref<boolean>(false);
  async function getUSDTWithdraw() {
    const m_user_c = login.CookieMUC();
    const postData = {
      m_user_c,
    };
    hasUSDT.value = false;
    const res = await APIfn.postMemberWithdrawalsUSDTPage(postData);
    //@ts-ignore 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    // console.log(res, "getUSDTWithdraw");
    switch (res.type) {
      case "0":
        hasUSDT.value = true;
        USDTWithdrawPage.value = res.dataList;
        break;
      case "1":
        USDTWithdrawPage.value = res.dataList;
        if (res.dataList.user_usdt.length <= 0) {
          store.toggleAlertBg();
          store.openWithdrawPageAlert();
          WithdrawPageAlertContent.value = t(
            "Store.Withdraw.VirtualWalletNotFound"
          );
        }
        break;
      case "2":
        await router.push({ name: "MemberAccount" });
        errorAlert.AddErrorAlert(res.msg);
        break;
      default:
        errorAlert.AddErrorAlert(res.msg);
        break;
    }
  }

  // 確認USDT託售
  async function sendUSDTWithdraw(data: WithdrawUSDTPageFromData) {
    const m_user_c = login.CookieMUC();
    const postData = {
      m_user_c,
      ...data,
    };
    const res = await APIfn.postMemberWithdrawalsUSDT(postData);
    // console.log(res, "postMemberWithdrawalsUSDT");
    errorAlert.AddErrorAlert(res.msg);
  }
  //#endregion

  //#region 新增銀行卡
  const addMemberBankMsg = ref<string>("");
  async function toMemberAddBank() {
    addMemberBankMsg.value = "";
    openAdminBankList.value = false;
    loadingPage.openLoadingPage();
    await CheckLogin(successMemberAddBank, failMemberAddBank);
  }
  async function successMemberAddBank() {
    // console.log("successMemberAddBank");
    await getCheckPwdBankAboutMemberAddBank();
    // getAdminBankList();
    loadingPage.closeLoadingPage();
    return;
  }
  function failMemberAddBank() {
    // console.log("failMemberAddBank");
    router.push({ name: "login" });

    loadingPage.closeLoadingPage();
    return;
  }

  // 檢查是否能進入 新增銀行卡
  async function getCheckPwdBankAboutMemberAddBank() {
    const m_user_c = login.CookieMUC();
    const postData = {
      m_user_c,
    };
    const res = await APIfn.postCheckPwdBank(postData);
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    CheckPwdBank.value = res.type;
    // console.log(res, "getCheckPwdBankData");
    switch (res.type) {
      case "0": // 有銀行卡
      case "5": // 銀行卡審核中
        await router.push({ name: "MemberBankPage" });
        store.toggleAlertBg();
        store.openWithdrawPageAlert();
        WithdrawPageAlertContent.value = res.msg;
        break;
      case "1": // 沒有交易密碼
        await router.push({ name: "ChangePasswordPage" });
        store.toggleAlertBg();
        store.openWithdrawPageAlert();
        WithdrawPageAlertContent.value = res.msg;
        break;
      // case "2": // 尚未實名認證
      case "2": //  尚未實名認證
        await router.push({ name: "BankVerified" });
        store.toggleAlertBg();
        store.openWithdrawPageAlert();
        WithdrawPageAlertContent.value = res.msg;
        break;
      case "3": // 實名認證審核中
      case "4": // 查無銀行卡
        getAdminBankList();
        break;
      default:
        errorAlert.AddErrorAlert(res.msg);
        break;
    }
  }

  // 取得管理員開啟可用銀行列表
  const AdminBankList = ref<APItypes.AdminBankList[]>([]);
  const openAdminBankList = ref<boolean>(false);
  async function getAdminBankList() {
    const m_user_c = login.CookieMUC();
    const postData = {
      m_user_c,
    };

    openAdminBankList.value = false;
    const res = await APIfn.postAdminBankList(postData);
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    openAdminBankList.value = true;
    // console.log(res, "getAdminBankListData");
    switch (res.type) {
      case "0":
        AdminBankList.value = res.dataList;
        break;
      default:
        errorAlert.AddErrorAlert(res.msg);
        break;
    }
  }

  // 新增會員銀行卡
  const keepFormValues = ref<AddBankFormData>({} as AddBankFormData);
  async function sendAddMemberBank(data: AddBankFormData) {
    const m_user_c = login.CookieMUC();
    const {
      sPay_num,
      nPay_card,
      sPay_address,
      sBranchCode,
      nBankType,
      sFile0,
      sFile1,
    } = data;
    const postData = new FormData();
    postData.append("m_user_c", m_user_c);
    postData.append("sPay_num", sPay_num);
    postData.append("nPay_card", nPay_card);
    postData.append("sPay_address", sPay_address);
    postData.append("sBranchCode", sBranchCode);
    postData.append("nBankType", nBankType.toString());
    postData.append("sFile0", sFile0);
    postData.append("sFile1", sFile1);
    loadingPage.openLoadingPage();
    const res = await APIfn.postAddMemberBank(postData);
    loadingPage.closeLoadingPage();
    // console.log(res, "sendAddMemberBank");
    switch (res.type) {
      case "0":
        // addMemberBankMsg.value = t("Store.Withdraw.SuccessSubmit");

        if (window.innerWidth < 768) {
          await router.push({ name: "Service" });
        } else {
          ToggleService(true);
          await router.push({ name: "MemberBankPage" });
        }
        store.toggleAlertBg();
        store.openWithdrawPageAlert();
        WithdrawPageAlertContent.value = t(
          "Store.Verified.VerifiedSuccessSubmit"
        );
        break;
      default:
        errorAlert.AddErrorAlert(res.msg);
        break;
    }
  }
  //
  //#endregion

  //#region 新增USDT
  const addMemberUSDTMsg = ref<string>("");
  async function toMemberAddUSDT() {
    addMemberUSDTMsg.value = "";
    openAdminUSDTList.value = false;
    loadingPage.openLoadingPage();
    await CheckLogin(successMemberAddUSDT, failMemberAddUSDT);
  }
  async function successMemberAddUSDT() {
    // console.log("successMemberAddUSDT");
    await getCheckPwdBankAboutMemberAddUSDT();
    await getCheckUSDTWithdraw();
    // getAdminUSDTList();
    loadingPage.closeLoadingPage();
    return;
  }

  function failMemberAddUSDT() {
    // console.log("failMemberAddUSDT");
    router.push({ name: "login" });

    loadingPage.closeLoadingPage();
    return;
  }

  // 檢查是否能進入 新增銀行卡
  async function getCheckPwdBankAboutMemberAddUSDT() {
    const m_user_c = login.CookieMUC();
    const postData = {
      m_user_c,
    };
    const res = await APIfn.postCheckPwdBank(postData);
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    CheckPwdBank.value = res.type;
    // console.log(res, "getCheckPwdBankData");
    switch (res.type) {
      case "1": // 沒有交易密碼
        await router.push({ name: "ChangePasswordPage" });
        store.toggleAlertBg();
        store.openWithdrawPageAlert();
        WithdrawPageAlertContent.value = res.msg;
        break;
      case "2": // 尚未實名認證
      case "3": // 實名認證審核中
        await router.push({ name: "BankVerified" });
        store.toggleAlertBg();
        store.openWithdrawPageAlert();
        WithdrawPageAlertContent.value = res.msg;
        break;
      case "4": // 查無銀行卡
      case "0": // 有銀行卡
      case "5": // 銀行卡審核中
        getAdminUSDTList();
        break;
      default:
        errorAlert.AddErrorAlert(res.msg);
        break;
    }
  }

  async function getCheckUSDTWithdraw() {
    const m_user_c = login.CookieMUC();
    const postData = {
      m_user_c,
    };
    const res = await APIfn.postMemberWithdrawalsUSDTPage(postData);
    //@ts-ignore 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    // console.log(res, "getUSDTWithdraw");
    switch (res.type) {
      case "0": // 有虛擬錢包
        await router.push({ name: "WithdrawPage" });
        break;
      case "1": // 沒有虛擬錢包
        break;
      case "2":
        await router.push({ name: "MemberAccount" });
        errorAlert.AddErrorAlert(res.msg);
        break;
      default:
        errorAlert.AddErrorAlert(res.msg);
        break;
    }
  }

  // 取得管理員開啟可用USDT列表
  const AdminUSDTList = ref<APItypes.AdminUSDTList[]>([]);
  const openAdminUSDTList = ref<boolean>(false);
  async function getAdminUSDTList() {
    const m_user_c = login.CookieMUC();
    const postData = {
      m_user_c,
    };

    openAdminUSDTList.value = false;
    const res = await APIfn.postAdminUSDTList(postData);
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    openAdminUSDTList.value = true;
    // console.log(res, "getAdminUSDTListData");
    switch (res.type) {
      case "0":
        AdminUSDTList.value = res.dataList;
        break;
      default:
        errorAlert.AddErrorAlert(res.msg);
        break;
    }
  }

  // 新增會員USDT
  async function sendAddMemberUSDT(data: AddUSDTFormData) {
    const m_user_c = login.CookieMUC();
    const { nPay_usdt, sPay_address } = data;
    const postData = new FormData();
    postData.append("m_user_c", m_user_c);
    postData.append("nPay_usdt", nPay_usdt);
    postData.append("sPay_address", sPay_address);
    const res = await APIfn.postAddMemberUSDT(postData);
    // console.log(res, "sendAddMemberUSDT");
    switch (res.type) {
      case "0":
        addMemberBankMsg.value = res.msg;
        await router.push({ name: "WithdrawPage" });
        errorAlert.AddErrorAlert(res.msg);
        break;
      default:
        errorAlert.AddErrorAlert(res.msg);
        break;
    }
  }
  //
  //#endregion

  //#region 銀行卡
  const openMemberBankcard = ref<boolean>(false);
  const CardData = ref<APItypes.MemberBankcardABankCard>(
    {} as APItypes.MemberBankcardABankCard
  );
  async function toMemberBankPage() {
    if (!login.isCashMember) return router.push({ name: "main" });
    loadingPage.openLoadingPage();
    CheckLogin(successMemberBankPage, failMemberBankPage);
  }
  async function successMemberBankPage() {
    // console.log("successMemberBankPage");
    openMemberBankcard.value = false;
    await getMemberBankcard();
    openMemberBankcard.value = true;

    loadingPage.closeLoadingPage();
    return;
  }
  function failMemberBankPage() {
    // console.log("failMemberBankPage");
    router.push({ name: "login" });

    loadingPage.closeLoadingPage();
    return;
  }

  async function getMemberBankcard() {
    const m_user_c = login.CookieMUC();
    const postData = {
      m_user_c,
    };
    const res = await APIfn.postMemberBankcard(postData);
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    // console.log(res, "getCheckMemberName");
    switch (res.type) {
      case 1:
        CardData.value = res.aBankCard;
        break;
      default:
        CardData.value = {} as APItypes.MemberBankcardABankCard;
        break;
    }
  }

  async function deleteMemberBankInBankPage(id: string) {
    if (id == "") {
      return;
    }
    if (confirm(t("Store.Withdraw.DeleteBankCardTip"))) {
      const m_user_c = login.CookieMUC();
      const postData = {
        m_user_c,
        withdrawal: id,
      };
      // console.log(postData);

      const res = await APIfn.postDeleteMemberBank(postData);
      // console.log(res, "postDeleteMemberBank");
      errorAlert.AddErrorAlert(res.msg);
      location.reload();
    }
  }
  //#endregion

  //#region 實名認證
  const openBankVerified = ref<boolean>(false);
  interface VerifyRealNameValue {
    sRealName: string;
    sBirthday: string;
    sIdCard: string;
    aFile0: Blob;
    aFile1: Blob;
  }

  async function toBankVerified() {
    loadingPage.openLoadingPage();
    await CheckLogin(successBankVerified, failBankVerified);
  }
  async function successBankVerified() {
    // console.log("successBankVerified");
    openBankVerified.value = false;
    await getCheckPwdBankAboutBankVerified();
    loadingPage.closeLoadingPage();
  }
  function failBankVerified() {
    // console.log("failBankVerified");
    router.push({ name: "login" });

    loadingPage.closeLoadingPage();
    return;
  }

  // 檢查是否可以進入實名認證
  async function getCheckPwdBankAboutBankVerified() {
    const m_user_c = login.CookieMUC();
    const postData = {
      m_user_c,
    };
    const res = await APIfn.postCheckPwdBank(postData);
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    CheckPwdBank.value = res.type;
    // console.log(res, "getCheckPwdBankData");
    switch (res.type) {
      case "0":
        await router.push({ name: "MemberAccount" });
        break;
      case "1": // 沒有交易密碼
        await router.push({ name: "ChangePasswordPage" });
        store.toggleAlertBg();
        store.openWithdrawPageAlert();
        WithdrawPageAlertContent.value = res.msg;
        break;
      case "2": // 尚未實名認證
        openBankVerified.value = true;
        break;
      case "3": // 實名認證審核中
        // await router.push({ name: "MemberAccount" });
        if (window.innerWidth > 768) {
          ToggleService(true);
        } else {
          await router.push({ name: "Service" });
        }
        store.toggleAlertBg();
        store.openWithdrawPageAlert();
        WithdrawPageAlertContent.value =
          res.msg + t("Store.Deposit.VerificationReview");
        break;
      case "4": // 查無銀行卡
      case "5": // 銀行卡審核中
        await router.push({ name: "MemberBankPage" });
        store.toggleAlertBg();
        store.openWithdrawPageAlert();
        WithdrawPageAlertContent.value = res.msg;
        break;

      default:
        errorAlert.AddErrorAlert(res.msg);
        break;
    }
  }

  async function submitVerifyRealName(data: VerifyRealNameValue) {
    const m_user_c = login.CookieMUC();
    const postData = new FormData();
    postData.append("m_user_c", m_user_c);
    postData.append("sRealName", data.sRealName);
    postData.append("sBirthday", data.sBirthday);
    postData.append("sIdCard", data.sIdCard);
    postData.append("aFile0", data.aFile0);
    postData.append("aFile1", data.aFile1);
    loadingPage.openLoadingPage();
    const res = await APIfn.PostVerifyRealName(postData);
    loadingPage.closeLoadingPage();
    // console.log(res, "getCheckMemberName");
    switch (res.nError) {
      case 0:
        if (window.innerWidth < 768) {
          await router.push({ name: "MemberAddBank" });
        } else {
          await router.push({ name: "MemberAddBank" });
        }
        // store.toggleAlertBg();
        // store.openWithdrawPageAlert();
        // WithdrawPageAlertContent.value = t(
        //   "Store.Verified.VerifiedSuccessSubmit"
        // );
        break;
      default:
        store.toggleAlertBg();
        store.openWithdrawPageAlert();
        WithdrawPageAlertContent.value = res.sMsg;
        break;
    }
  }
  //#endregion

  //#region 交易密碼
  async function toChangePasswordPage() {
    loadingPage.openLoadingPage();
    await CheckLogin(successChangePasswordPage, failChangePasswordPage);
  }
  async function successChangePasswordPage() {
    // console.log("successChangePasswordPage");
    await getCheckPwdBankAboutChangePwd();

    loadingPage.closeLoadingPage();
    return;
  }
  function failChangePasswordPage() {
    // console.log("failChangePasswordPage");
    router.push({ name: "login" });

    loadingPage.closeLoadingPage();
    return;
  }
  // 確認是真的沒有交易密碼才能進入
  async function getCheckPwdBankAboutChangePwd() {
    const m_user_c = login.CookieMUC();
    const postData = {
      m_user_c,
    };
    const res = await APIfn.postCheckPwdBank(postData);
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    // console.log(res, "getCheckPwdBankAboutChangePwd");
    switch (res.type) {
      case "0":
        router.push({ name: "WithdrawPage" });
        break;
      case "1":
        // 沒有交易密碼
        router.push({ name: "ChangePasswordPage" });
        break;
      case "2":
        await router.push({ name: "MemberAddBank" });
        store.toggleAlertBg();
        store.openWithdrawPageAlert();
        WithdrawPageAlertContent.value = res.msg;
        break;
      default:
        // console.log(res.msg);
        break;
    }
  }

  async function ChangePasswordAboutChangePwd(data: APItypes.ChangepwdData) {
    const res: APItypes.ChangepwdResponse = await APIfn.postChangepwd(data);
    // console.log(res, "ChangePassword");
    switch (res.type) {
      case "0":
        await router.push({ name: "WithdrawPage" });
        store.toggleAlertBg();
        store.openWithdrawPageAlert();
        WithdrawPageAlertContent.value = res.msg;
        break;
      case "1":
        WithdrawPageAlertContent.value = t(
          "Store.ChangePassword.OldPasswordInputError"
        );
        break;
      default:
        WithdrawPageAlertContent.value = res.msg;
        break;
    }
    return res.type;
  }
  //#endregion

  //#region 儲值
  const MemberRecharge = ref<APItypes.MemberRechargePageList>(
    {} as APItypes.MemberRechargePageList
  );
  const MemberRecharge2 = ref<APItypes.MemberRechargePage2List>(
    {} as APItypes.MemberRechargePage2List
  );
  const MemberRechargeList = computed(() => {
    const MemberRechargeAry: APItypes.MemberRechargePageList = Object.keys(
      MemberRecharge.value
    ).reduce((obj, key) => {
      if (key === "postUrl") return obj;
      return { ...obj, [key]: MemberRecharge.value[key] };
    }, {});
    return MemberRechargeAry;
  });
  const MemberRechargePostUrl = computed(() => {
    const MemberRechargeUrl = MemberRecharge.value.postUrl;
    //@ts-ignore
    return MemberRechargeUrl as string;
  });

  const MemberRecharge2List = computed(() => {
    const MemberRechargeAry = Object.values(MemberRecharge2.value).map(
      (item) => {
        return Object.values(item)[0];
      }
    );
    return MemberRechargeAry;
  });

  const MemberCompanyDeposit = ref<APItypes.MemberCompanyDepositPageList[]>([]);
  const DepositStoreList = ref<APItypes.CheckMemberStores[]>([]);
  const MemberUSDTDeposit = ref<APItypes.MemberUSDTDepositPageDataList>(
    {} as APItypes.MemberUSDTDepositPageDataList
  );
  const MemberUSDTPlatform = computed(() => {
    if (JSON.stringify(MemberUSDTDeposit.value) === "{}") return [];
    return MemberUSDTDeposit.value.aPlatform;
  });
  const hasUSDTForm = ref<boolean>(false);
  const USDTFormInfo = ref<APItypes.MemberUSDTDepositDetailaDetail>(
    {} as APItypes.MemberUSDTDepositDetailaDetail
  );

  async function toDepositPage() {
    if (!login.isCashMember) return router.push({ name: "main" });
    loadingPage.openLoadingPage();
    await CheckLogin(successDepositPage, failDepositPage);
  }
  async function successDepositPage() {
    // console.log("successDepositPage");
    await getDepositPageCheckPwdBank();

    loadingPage.closeLoadingPage();
    return;
  }
  async function failDepositPage() {
    // console.log("failDepositPage");
    router.push({ name: "login" });

    loadingPage.closeLoadingPage();
    return;
  }

  async function getDepositPageCheckPwdBank() {
    const m_user_c = login.CookieMUC();
    const postData = {
      m_user_c,
    };
    hasBankCard.value = false;
    const res = await APIfn.postCheckPwdBank(postData);
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    CheckPwdBank.value = res.type;
    // console.log(res, "getCheckPwdBankData");
    switch (res.type) {
      case "0":
        // getMemberRechargePage();
        getMemberRechargePage2();
        getMemberCompanyDepositPage();
        getMemberUSDTDepositPage();
        getCheckMemberStore();
        getDepositListData();
        break;
      case "1": // 沒有交易密碼
        await router.push({ name: "ChangePasswordPage" });
        store.toggleAlertBg();
        store.openWithdrawPageAlert();
        WithdrawPageAlertContent.value = res.msg;
        break;
      case "2": // 尚未實名認證
        await router.push({ name: "BankVerified" });
        store.toggleAlertBg();
        store.openWithdrawPageAlert();
        WithdrawPageAlertContent.value = t(
          "Store.Deposit.VerificationForFirstDeposit"
        );
        break;
      case "3": // 實名認證審核中
        if (window.innerWidth > 768) {
          ToggleService(true);
        } else {
          await router.push({ name: "Service" });
        }
        // await router.push({ name: "MemberAccount" });
        store.toggleAlertBg();
        store.openWithdrawPageAlert();
        WithdrawPageAlertContent.value =
          res.msg + t("Store.Deposit.VerificationReview");
        break;
      case "4": // 查無銀行卡
      case "5": // 銀行卡審核中
        await router.push({ name: "MemberBankPage" });
        store.toggleAlertBg();
        store.openWithdrawPageAlert();
        WithdrawPageAlertContent.value = res.msg;
        break;

      default:
        errorAlert.AddErrorAlert(res.msg);
        break;
    }
  }

  // 線上儲值畫面
  async function getMemberRechargePage() {
    const m_user_c = login.CookieMUC();
    const postData = {
      m_user_c,
    };
    const res = await APIfn.postMemberRechargePage(postData);
    //@ts-ignore 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    // console.log(res, "getMemberRechargePage");
    switch (res.type) {
      case "0":
        MemberRecharge.value = res.dataList;
        break;
      default:
        // errorAlert.AddErrorAlert(res.msg);
        break;
    }
  }

  // 線上儲值畫面2
  async function getMemberRechargePage2() {
    const m_user_c = login.CookieMUC();
    const postData = {
      m_user_c,
    };
    const res = await APIfn.postMemberRechargePage2(postData);
    //@ts-ignore 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    // console.log(res, "getMemberRechargePage");
    switch (res.type) {
      case "0":
        MemberRecharge2.value = res.dataList;
        break;
      default:
        errorAlert.AddErrorAlert(res.msg);
        break;
    }
  }

  //線上儲值
  async function getMemberRecharge(data: OnlineDepositPostData) {
    const {
      nId,
      sPay_name,
      sNickname,
      nMax,
      nMin,
      sGids,
      sPayType,
      nMoney,
      t,
      a,
      CVS,
      StorePayment,
      BarcodePayment,
      TelecomPayment,
    } = data;
    let Url = MemberRechargePostUrl.value;
    const { nUid } = login.memberData;
    const postData = new FormData();
    postData.append("nId", nId);
    postData.append("nUid", nUid);
    postData.append("sPay_name", sPay_name);
    postData.append("sNickname", sNickname);
    postData.append("nMax", nMax);
    postData.append("nMin", nMin);
    postData.append("sGids", sGids);
    postData.append("sPayType", sPayType);
    postData.append("nMoney", nMoney);
    postData.append("t", t);
    postData.append("a", a);
    Url += `&t=${t}`;
    Url += `&a=${a}`;
    Url += `&nId=${nId}`;
    Url += `&nUid=${nUid}`;
    Url += `&sNickname=${sNickname}`;
    Url += `&sPay_name=${sPay_name}`;
    Url += `&nMoney=${nMoney}`;
    Url += `&sPayType=${sPayType}`;

    Url += `&nMax=${nMax}`;
    Url += `&nMin=${nMin}`;
    Url += `&sGids=${sGids}`;
    if (CVS) {
      postData.append("sStore", CVS);
      Url += `&sStore=${CVS}`;
    }
    if (StorePayment) {
      postData.append("sStore", StorePayment);
      Url += `&sStore=${StorePayment}`;
    }
    if (BarcodePayment) {
      postData.append("sBarcode", BarcodePayment);
      Url += `&sBarcode=${BarcodePayment}`;
    }
    if (TelecomPayment) {
      postData.append("sTelecom", TelecomPayment);
      Url += `&sTelecom=${TelecomPayment}`;
    }

    // console.log(data, "getMemberRecharge");
    // window.location.href = Url;
    window.open(Url, "_blank");
    // axios
    //   .get(Url, {
    //     params: postData,
    //   })
    //   .then((res) => {
    // console.log(res, Url, "getMemberRecharge");
    //   });
  }

  async function getMemberRecharge2(data: OnlineDepositPost2Data) {
    const {
      nId,
      sPay_name,
      sNickname,
      nMax,
      nMin,
      sGids,
      nMoney,
      t,
      a,
      aTunnel,
      postUrl,
    } = data;
    let Url = postUrl;
    const { nUid } = login.memberData;
    const postData = new FormData();
    postData.append("nId", nId);
    postData.append("nUid", nUid);
    postData.append("sPay_name", sPay_name);
    postData.append("sNickname", sNickname);
    postData.append("nMax", nMax);
    postData.append("nMin", nMin);
    postData.append("sGids", sGids);
    postData.append("sPayType", aTunnel);
    postData.append("nMoney", nMoney);
    postData.append("t", t);
    postData.append("a", a);
    Url += `&t=${t}`;
    Url += `&a=${a}`;
    Url += `&nId=${nId}`;
    Url += `&nUid=${nUid}`;
    Url += `&sNickname=${sNickname}`;
    Url += `&sPay_name=${sPay_name}`;
    Url += `&nMoney=${nMoney}`;
    Url += `&sPayType=${aTunnel}`;

    Url += `&nMax=${nMax}`;
    Url += `&nMin=${nMin}`;
    Url += `&sGids=${sGids}`;

    console.log("getMemberRecharge");
    window.open(Url, "_blank");
    // window.location.href = Url;
    // axios
    //   .get(Url, {
    //     params: postData,
    //   })
    //   .then((res) => {
    // console.log(res, Url, "getMemberRecharge");
    //   });
  }

  // 銀行轉帳畫面
  async function getMemberCompanyDepositPage() {
    const m_user_c = login.CookieMUC();
    const postData = {
      m_user_c,
    };
    const res = await APIfn.postMemberCompanyDepositPage(postData);
    //@ts-ignore 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    // console.log(res, "getMemberCompanyDepositPage");
    switch (res.type) {
      case "0":
        MemberCompanyDeposit.value = res.dataList;
        break;
      default:
        // errorAlert.AddErrorAlert(res.msg);
        break;
    }
  }

  // 銀行轉帳
  const MemberCompanyDepositMsg = ref<string>("");
  async function getMemberCompanyDeposit(data: MemberCompanyDepositFormData) {
    const m_user_c = login.CookieMUC();
    const postData = {
      m_user_c,
      ...data,
    };
    const res = await APIfn.postMemberCompanyDeposit(postData);
    // console.log(res, "getMemberCompanyDeposit");
    errorAlert.AddErrorAlert(res.msg);
    switch (res.type) {
      case "0":
        MemberCompanyDepositMsg.value = res.msg;
        // console.log(res.msg);
        break;
      default:
        // console.log(res.msg);
        break;
    }
  }

  // 超商綁定資料
  async function getCheckMemberStore() {
    const m_user_c = login.CookieMUC();
    const postData = {
      m_user_c,
    };
    const res = await APIfn.postCheckMemberStore(postData);
    if (maintain.isMaintain(res)) return;
    // console.log(res, "getCheckMemberStore");
    switch (res.type) {
      case "0":
        DepositStoreList.value = res.dataList;
        break;
      default:
        DepositStoreList.value = [];
        break;
    }
  }

  //超商綁定
  async function getAddMemberStore(data: AddStoreDepositFormData) {
    const m_user_c = login.CookieMUC();
    const postData = {
      m_user_c,
      ...data,
    };
    const res = await APIfn.postAddMemberStore(postData);
    //@ts-ignore 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    console.log(res, "getAddMemberStore");
    switch (res.type) {
      case 1:
        errorAlert.AddErrorAlert(res.msg);
        getCheckMemberStore();
        break;
      default:
        errorAlert.AddErrorAlert(res.msg);
        getCheckMemberStore();
        break;
    }
  }

  // USDT畫面
  async function getMemberUSDTDepositPage() {
    const m_user_c = login.CookieMUC();
    const postData = {
      m_user_c,
    };
    const res = await APIfn.postMemberUSDTDepositPage(postData);
    //@ts-ignore 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    // console.log(res, "getMemberUSDTDepositPage");
    switch (res.type) {
      case "0":
        MemberUSDTDeposit.value = res.dataList;
        hasUSDTForm.value = false;
        break;
      case "4":
        getMemberUSDTDepositDetail();
        hasUSDTForm.value = true;
        break;
      default:
        // errorAlert.AddErrorAlert(res.msg);
        break;
    }
  }

  // USDT入款
  async function getMemberUSDTDeposit(data: USDTFormData) {
    const m_user_c = login.CookieMUC();
    const postData = {
      m_user_c,
      ...data,
    };
    const res = await APIfn.postMemberUSDTDeposit(postData);
    //@ts-ignore 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    // console.log(res, "getMemberUSDTDeposit");
    switch (res.type) {
      case "0":
        errorAlert.AddErrorAlert(res.msg);
        hasUSDTForm.value = true;
        getMemberUSDTDepositDetail();
        break;
      default:
        errorAlert.AddErrorAlert(res.msg);
        break;
    }
  }
  // Usdt入款資訊;
  async function getMemberUSDTDepositDetail() {
    const m_user_c = login.CookieMUC();
    const postData = {
      m_user_c,
    };
    const res = await APIfn.postMemberUSDTDepositDetail(postData);
    //@ts-ignore 檢查維護狀態
    if (maintain.isMaintain(res)) return;

    switch (res.type) {
      case "0":
        USDTFormInfo.value = res.dataList.aDetail;
        hasUSDTForm.value = true;
        break;
      default:
        hasUSDTForm.value = false;
        errorAlert.AddErrorAlert(res.msg);
        break;
    }
    // console.log(res, USDTFormInfo.value, "getMemberUSDTDepositDetail");
  }

  // 儲值紀錄
  const DepositListPage = ref<number>(1);
  const DepositListPerPage = ref<number>(10); //單頁筆數
  const DepositList = ref<APItypes.ADepositList[]>([]);
  const TotalDepositListPage = computed(() => {
    return Math.ceil(DepositList.value.length / DepositListPerPage.value);
  });
  const CurrentDepositList = computed(() => {
    const start = (DepositListPage.value - 1) * DepositListPerPage.value;
    const end = start + DepositListPerPage.value;
    return DepositList.value.slice(start, end);
  });

  const defaultDepositList = ref<APItypes.DepositListData>({
    sStartTime: timeListLogic.getDataDate(timeListLogic.getTodayDay()),
    sEndTime: timeListLogic.getDataDate(EndDay()),
    m_user_c: "",
  });
  const postDepositListData = computed(() => {
    const { sStartTime, sEndTime } = defaultDepositList.value;
    // console.log(login.CookieMUC(), "postDepositListData");
    return {
      sStartTime: sStartTime + " " + login.forStartTime,
      sEndTime: sEndTime + " " + login.forEndTime,
      m_user_c: login.CookieMUC(),
    };
  });

  async function getDepositListData(
    data: APItypes.DepositListData = postDepositListData.value
  ) {
    const res = await APIfn.PostDepositList({
      ...data,
      m_user_c: login.CookieMUC(),
    });
    // console.log(data, "getDepositListData");
    // console.log(res, "getDepositListData");
    switch (res.type) {
      case 1:
        DepositList.value = Object.values(res.aDepositList);
        break;
      default:
        DepositList.value = [];
        // errorAlert.AddErrorAlert(res.sMsg);
        break;
    }
    DepositListPage.value = 1;
  }

  // 換頁
  async function goDepositListPage(page: number) {
    DepositListPage.value = page;
  }

  //#endregion

  //#region 排行榜
  const RankingList = ref<APItypes.LeaderBoardListData | any[]>([]);
  // 排行陣列
  const RankingMemberList = computed((): APItypes.LeaderBoardListJson[] => {
    if (Array.isArray(RankingList.value)) return [];
    if (JSON.stringify(RankingList.value) === "{}") return [];
    if (RankingList.value.sJson === "") return [];

    const objList: {
      [key: string]: APItypes.LeaderBoardListJson;
    } = JSON.parse(RankingList.value.sJson);
    const aryList: APItypes.LeaderBoardListJson[] = Object.values(objList);
    return aryList.sort((a: { nMoney: string }, b: { nMoney: string }) => {
      //@ts-ignore
      return b.nMoney - a.nMoney;
    });
  });
  // 更新時間
  const RankingMemberUpdateTime = computed(() => {
    if (Array.isArray(RankingList.value)) return "";
    if (JSON.stringify(RankingList.value) === "{}") return "";
    return RankingList.value.sUpdateTime;
  });
  // 自己的排行
  const RankingMemberSelf = computed(() => {
    let selfIndex = NaN;
    const self = RankingMemberList.value.find((item, index) => {
      if (item.sAccount === login.memberData.sAccount) {
        selfIndex = index;
      }
      return item.sAccount === login.memberData.sAccount;
    });

    if (self) return { index: selfIndex, ...self };
    return {
      index: selfIndex,
      sAccount: "",
      nVipLv: "",
      nMoney: "0",
    };
  });
  const nDataType = ref<string>("1"); // 1:洗碼量排行 , 2: 儲值量排行
  const nDay = ref<string>("1"); // 1: 本日, 2: 昨日, 3:本週, 4: 上週, 5:本月

  function changeDataType(data: RankingListData) {
    // console.log(data);
    nDataType.value = data.nDataType;
    nDay.value = data.nDay;
    const postData = { nDataType: nDataType.value, nDay: nDay.value };
    getRankingListData(postData);
  }

  async function toRankingListPage() {
    if (!login.isCashMember) return router.push({ name: "main" });
    loadingPage.openLoadingPage();
    await CheckLogin(successRankingListPage, failRankingListPage);
  }
  async function successRankingListPage() {
    // console.log("successRankingListPage");
    getRankingListData({ nDataType: nDataType.value, nDay: nDay.value });
    loadingPage.closeLoadingPage();
    return;
  }
  async function failRankingListPage() {
    // console.log("failRankingListPage");
    router.push({ name: "login" });

    loadingPage.closeLoadingPage();
    return;
  }

  interface RankingListData {
    nDataType: string;
    nDay: string;
  }
  async function getRankingListData(data: RankingListData) {
    const res = await APIfn.PostLeaderBoard({
      ...data,
      m_user_c: login.CookieMUC(),
    });
    // console.log(data, res, "getRankingListData");
    switch (res.type) {
      case 0:
        RankingList.value = res.aLeaderboard;
        break;
      default:
        RankingList.value = {} as APItypes.LeaderBoardListData;
        errorAlert.AddErrorAlert(res.sMsg);
        break;
    }
    DepositListPage.value = 1;
  }
  //#endregion

  //#region 新增重複銀行卡
  const addMemberCardMsg = ref<string>("");
  async function toMemberAddCard() {
    addMemberCardMsg.value = "";
    openAdminBankList.value = false;
    loadingPage.openLoadingPage();
    await CheckLogin(successMemberAddCard, failMemberAddCard);
  }
  async function successMemberAddCard() {
    // console.log("successMemberAddCard");
    await getCheckPwdBankAboutMemberAddCard();
    // getAdminBankList();
    loadingPage.closeLoadingPage();
    return;
  }
  function failMemberAddCard() {
    // console.log("failMemberAddCard");
    router.push({ name: "login" });

    loadingPage.closeLoadingPage();
    return;
  }

  // 檢查是否能進入 新增銀行卡
  async function getCheckPwdBankAboutMemberAddCard() {
    const m_user_c = login.CookieMUC();
    const postData = {
      m_user_c,
    };
    const nAddCard = login.memberData.nAddCard;
    if (nAddCard === "0") {
      await router.push({ name: "MemberBankPage" });
    }
    const res = await APIfn.postCheckPwdBank(postData);
    // 檢查維護狀態
    if (maintain.isMaintain(res)) return;
    CheckPwdBank.value = res.type;
    // console.log(res, "getCheckPwdBankData");
    switch (res.type) {
      case "5": // 銀行卡審核中
        await router.push({ name: "MemberBankPage" });
        store.toggleAlertBg();
        store.openWithdrawPageAlert();
        WithdrawPageAlertContent.value = res.msg;
        break;
      case "1": // 沒有交易密碼
        await router.push({ name: "ChangePasswordPage" });
        store.toggleAlertBg();
        store.openWithdrawPageAlert();
        WithdrawPageAlertContent.value = res.msg;
        break;
      // case "2": // 尚未實名認證
      case "2": //  尚未實名認證
        await router.push({ name: "BankVerified" });
        store.toggleAlertBg();
        store.openWithdrawPageAlert();
        WithdrawPageAlertContent.value = res.msg;
        break;
      case "0": // 有銀行卡
      case "3": // 實名認證審核中
      case "4": // 查無銀行卡
        getAdminBankList();
        break;
      default:
        errorAlert.AddErrorAlert(res.msg);
        break;
    }
  }

  // 新增會員銀行卡
  const keepCardFormValues = ref<AddBankFormData>({} as AddBankFormData);
  async function sendAddCardMemberBank(data: AddBankFormData) {
    const m_user_c = login.CookieMUC();
    const {
      sPay_num,
      nPay_card,
      sPay_address,
      sBranchCode,
      nBankType,
      sFile0,
      sFile1,
    } = data;
    const postData = new FormData();
    postData.append("m_user_c", m_user_c);
    postData.append("sPay_num", sPay_num);
    postData.append("nPay_card", nPay_card);
    postData.append("sPay_address", sPay_address);
    postData.append("sBranchCode", sBranchCode);
    postData.append("nBankType", nBankType.toString());
    postData.append("sFile0", sFile0);
    postData.append("sFile1", sFile1);
    loadingPage.openLoadingPage();
    const res = await APIfn.postAddMemberBank(postData);
    loadingPage.closeLoadingPage();
    // console.log(res, "sendAddMemberBank");
    switch (res.type) {
      case "0":
        // addMemberCardMsg.value = t("Store.Withdraw.SuccessSubmit");

        if (window.innerWidth < 768) {
          await router.push({ name: "Service" });
        } else {
          ToggleService(true);
          await router.push({ name: "MemberBankPage" });
        }
        store.toggleAlertBg();
        store.openWithdrawPageAlert();
        WithdrawPageAlertContent.value = t(
          "Store.Verified.VerifiedSuccessSubmit"
        );
        break;
      default:
        errorAlert.AddErrorAlert(res.msg);
        break;
    }
  }
  //
  //#endregion
  return {
    CheckLogin,
    getMailCount,
    MailCount,
    toMemberAccount,
    toMemberManagement,
    ChangePassword,
    hasTradePwd,
    changepwdMsg,
    ClearChangepwdMsg,
    MailUpdate,
    LineUpdate,
    isService,
    openService,
    closeService,
    ToggleService,
    isShowService,
    getWebTalkLineService,
    toMemberMail,
    getMailBoxData,
    defaultMailBoxData,
    MailBox,
    MailBoxPage,
    CurrentMailBox,
    TotalMailBoxPage,
    goMailBoxPage,
    getMailBoxAlertContent,
    MailBoxAlertContent,
    deleteMailBoxAlert,
    DeleteHaveReadMail,
    DeleteAlertId,
    getDeleteAlertId,
    ReadAllMail,
    ReadMail,
    toService,
    WebTalk,
    getWebTalk,
    stopTimeoutToGetWebTalk,
    sendWebTalk,
    toTradeRecord,
    getTradeRecordData,
    TradeRecordPage,
    defaultTransactionRecord,
    goTradeRecordPage,
    TotalTradeRecordPage,
    CurrentTradeRecord,
    TradeRecordAlertContent,
    getTradeRecordAlertContent,
    toBetRecord,
    defaultBetRecordData,
    goBetRecordPage,
    getBetRecordData,
    CurrentBetRecord,
    TotalBetRecordPage,
    BetRecordPage,
    BetRecordAlertContent,
    getBetRecordAlertContent,
    defaultBetStatisticsData,
    BetStatisticsAGame,
    BetStatisticsASum,
    getBetStatisticsData,
    toApiBalance,
    isAllMoneyBacking,
    getAllMoneyBackData,
    ApiBalance,
    SortApiBalance,
    toUserList,
    CurrentUserList,
    TotalUserListPage,
    UserListPage,
    goUserListPage,
    getUserListData,
    getUserListAlertContent,
    UserListAlertContent,
    toPromotion,
    CurrentPromotion,
    PromotionPage,
    TotalPromotionPage,
    goPromotionPage,
    getPromotionAlertContent,
    PromotionAlertContent,
    getDeletePromotionId,
    deletePromotion,
    addPromotion,
    sendTransferPage,
    toTransferPage,
    transferPageMsg,
    toWithdrawPage,
    hasBankCard,
    hasUSDT,
    WithdrawPageAlertContent,
    MemberWithdrawPage,
    sendMemberWithdrawals,
    sendUSDTWithdraw,

    // 託售列表
    WithdrawalsPage,
    WithdrawalsPerPage,
    Withdrawals,
    TotalWithdrawalsPage,
    CurrentWithdrawals,
    defaultWithdrawals,
    getWithdrawalsData,
    goWithdrawalsPage,

    toUSDTWithdrawPage,
    USDTWithdrawPage,
    CheckPwdBank,
    toMemberAddBank,
    toMemberAddUSDT,
    AdminBankList,
    AdminUSDTList,
    openAdminBankList,
    openAdminUSDTList,
    CardData,
    keepFormValues,
    openMemberBankcard,
    toMemberBankPage,
    sendAddMemberBank,
    sendAddMemberUSDT,
    addMemberBankMsg,
    deleteMemberBankInBankPage,
    openBankVerified,
    toBankVerified,
    submitVerifyRealName,
    toChangePasswordPage,
    ChangePasswordAboutChangePwd,
    toDepositPage,
    MemberCompanyDeposit,
    DepositStoreList,
    getAddMemberStore,
    MemberUSDTDeposit,
    MemberUSDTPlatform,
    hasUSDTForm,
    USDTFormInfo,
    getMemberUSDTDeposit,
    getMemberCompanyDeposit,
    MemberCompanyDepositMsg,
    MemberRecharge,
    MemberRechargeList,
    MemberRecharge2List,
    getMemberRecharge,
    getMemberRecharge2,
    toVipPrivilege,
    VipList,
    VipListSort,
    VipListMaxLv,
    changeBank,
    changeBankName,
    changeBankBranch,
    changeBankNid,
    deleteMemberBank,
    toNewMessage,
    getNewMessageData,
    selectNewsContentAlert,
    NewMes,
    NewJumpMes,
    NewJumpContent,
    toShareMakeFortunePage,
    ShareData,
    toDiscountPage,
    toShareRecord,
    ShareList,
    ShareListCount,
    toShareFsLog,
    ShareFsLog,
    ShareFsLogCount,
    goShareFsLogPage,
    getShareFsLog,
    defaultShareFsLog,
    CurrentShareFsLog,
    TotalShareFsLogPage,
    ShareFsLogPage,

    // 儲值列表
    DepositListPage,
    DepositListPerPage,
    DepositList,
    TotalDepositListPage,
    CurrentDepositList,
    defaultDepositList,
    getDepositListData,
    goDepositListPage,

    // 排行榜
    RankingMemberList,
    RankingMemberUpdateTime,
    toRankingListPage,
    changeDataType,
    nDataType,
    nDay,
    RankingMemberSelf,

    // 新增重複銀行卡
    addMemberCardMsg,
    toMemberAddCard,
    keepCardFormValues,
    sendAddCardMemberBank,
  };
});
