<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Scrollbar } from "swiper/modules";
import "swiper/css/bundle";

import { ref, computed, onMounted, onUnmounted } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";

import { useMemberService } from "@/stores/memberFunction/memberService";
import { useLogin } from "@/stores/login";

import centerBtnsImg1 from "@image/memberFunction/05/my_my_01btn.png";
import centerBtnsImg2 from "@image/memberFunction/05/my_vip_02btn.png";
import centerBtnsImg3 from "@image/memberFunction/05/my_member_03btn.png";
import centerBtnsImg4 from "@image/memberFunction/05/my_trade_04btn.png";
import centerBtnsImg5 from "@image/memberFunction/05/my_bet_05btn.png";
import centerBtnsImg6 from "@image/memberFunction/05/my_money_06btn.png";
import centerBtnsImg8 from "@image/memberFunction/05/my_mail_07btn.png";
import centerBtnsImg7 from "@image/memberFunction/05/my_cs_08btn.png";
import centerBtnsImg9 from "@image/memberFunction/05/my_import_09btn.png";

const { t } = useI18n();
const router = useRouter();
const memberService = useMemberService();
const login = useLogin();
const { isCashMember } = storeToRefs(login);

const modules = [Navigation];

function toPath(title: string) {
  switch (title) {
    case t("MemberFunction.MemberAccount.MemberManagement"):
      router.push({ name: "MemberManagement" });
      break;
    case t("MemberFunction.MemberAccount.VIPPrivilege"):
      router.push({ name: "VIPPrivilege" });
      break;
    case t("MemberFunction.MemberAccount.MemberList"):
      router.push({ name: "MemberList" });
      break;
    case t("MemberFunction.MemberAccount.TradeRecord"):
      router.push({ name: "TradeRecord" });
      break;
    case t("MemberFunction.MemberAccount.BetRecord"):
      router.push({ name: "BetRecord" });
      break;
    case t("MemberFunction.MemberAccount.TakeOverCoin"):
      router.push({ name: "TakeOverCoin" });
      break;
    case t("MemberFunction.MemberAccount.Service"):
      router.push({ name: "Service" });
      break;
    case t("MemberFunction.MemberAccount.MemberMail"):
      router.push({ name: "MemberMail" });
      break;
    case t("MemberFunction.MemberAccount.NewMessage"):
      router.push({ name: "NewMessage" });
      break;
    default:
      console.log("default");
      break;
  }
}

const centerBtns = computed(() => {
  if (isCashMember.value) {
    if (login.memberData.nRank === "0" || login.memberData.nRank === "") {
      return [
        t("MemberFunction.MemberAccount.MemberManagement"),
        t("MemberFunction.MemberAccount.VIPPrivilege"),
        t("MemberFunction.MemberAccount.TradeRecord"),
        t("MemberFunction.MemberAccount.BetRecord"),
        t("MemberFunction.MemberAccount.TakeOverCoin"),
        t("MemberFunction.MemberAccount.MemberMail"),
        t("MemberFunction.MemberAccount.Service"),
        t("MemberFunction.MemberAccount.NewMessage"),
      ];
    } else {
      return [
        t("MemberFunction.MemberAccount.MemberManagement"),
        t("MemberFunction.MemberAccount.VIPPrivilege"),
        t("MemberFunction.MemberAccount.MemberList"),
        t("MemberFunction.MemberAccount.TradeRecord"),
        t("MemberFunction.MemberAccount.BetRecord"),
        t("MemberFunction.MemberAccount.TakeOverCoin"),
        t("MemberFunction.MemberAccount.MemberMail"),
        t("MemberFunction.MemberAccount.Service"),
        t("MemberFunction.MemberAccount.NewMessage"),
      ];
    }
  } else {
    if (login.memberData.nRank === "0" || login.memberData.nRank === "") {
      return [
        t("MemberFunction.MemberAccount.MemberManagement"),
        t("MemberFunction.MemberAccount.BetRecord"),
        t("MemberFunction.MemberAccount.TakeOverCoin"),
        t("MemberFunction.MemberAccount.MemberMail"),
        t("MemberFunction.MemberAccount.Service"),
        t("MemberFunction.MemberAccount.NewMessage"),
      ];
    } else {
      return [
        t("MemberFunction.MemberAccount.MemberManagement"),
        t("MemberFunction.MemberAccount.MemberList"),
        t("MemberFunction.MemberAccount.BetRecord"),
        t("MemberFunction.MemberAccount.TakeOverCoin"),
        t("MemberFunction.MemberAccount.MemberMail"),
        t("MemberFunction.MemberAccount.Service"),
        t("MemberFunction.MemberAccount.NewMessage"),
      ];
    }
  }
});

const getCenterBtnsImg = (title: string) => {
  let image;
  switch (title) {
    case t("MemberFunction.MemberAccount.MemberManagement"):
      image = centerBtnsImg1;
      break;
    case t("MemberFunction.MemberAccount.VIPPrivilege"):
      image = centerBtnsImg2;
      break;
    case t("MemberFunction.MemberAccount.MemberList"):
      image = centerBtnsImg3;
      break;
    case t("MemberFunction.MemberAccount.TradeRecord"):
      image = centerBtnsImg4;
      break;
    case t("MemberFunction.MemberAccount.BetRecord"):
      image = centerBtnsImg5;
      break;
    case t("MemberFunction.MemberAccount.TakeOverCoin"):
      image = centerBtnsImg6;
      break;
    case t("MemberFunction.MemberAccount.Service"):
      image = centerBtnsImg7;
      break;
    case t("MemberFunction.MemberAccount.MemberMail"):
      image = centerBtnsImg8;
      break;
    case t("MemberFunction.MemberAccount.NewMessage"):
      image = centerBtnsImg9;
      break;
    default:
      break;
  }
  return image;
};
</script>

<template>
  <div
    class="my-btn-bg-neon-border px-[1.5em] py-[12px] flex justify-center items-center mt-1 mb-3">
    <ul class="grid grid-cols-3 grid-rows-2">
      <li
        v-for="(item, index) in centerBtns"
        :key="item + 'centerBtns'"
        class="flex flex-col justify-start items-center px-[1em] p-[.3em] relative">
        <!-- :class="
          index % 3 !== 2
            ? 'after:absolute after:h-[30%] after:w-[3px] after:bg-white/30 after:left-[85%] after:top-[40%] after:-translate-y-1/2 after:ml-[.8em]'
            : ''
        " -->
        <button @click="toPath(item)" class="">
          <img :src="getCenterBtnsImg(item)" alt="" class="w-full" />
        </button>
        <p
          class="text-center text-primary text-[14px] font-bold my-[.5dvh] w-[130%]">
          {{ item }}
        </p>
        <span
          class="absolute right-0 -translate-x-1/2 top-0 flex justify-center items-center rounded-full h-[24px] w-[24px] bg-red-500"
          v-if="
            parseInt(memberService.MailCount) > 0 &&
            item === $t('MemberFunction.MemberAccount.MemberMail')
          ">
          <p class="text-primary">{{ memberService.MailCount }}</p>
        </span>
      </li>
    </ul>
  </div>
</template>
<style>
.my-btn-bg-neon-border {
  border-style: solid;
  border-width: 1px;
  -moz-border-image: url("@image/memberFunction/05/my_btn_bg.png") 1;
  -webkit-border-image: url("@image/memberFunction/05/my_btn_bg.png") 1;
  -o-border-image: url("@image/memberFunction/05/my_btn_bg.png") 1;
  border-image: url("@image/memberFunction/05/my_btn_bg.png") 1 fill;
}
</style>
