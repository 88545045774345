<script setup lang="ts">
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useLogin } from "@/stores/login";
import { useCounterStore } from "@/stores/counter";
import { useMemberService } from "@/stores/memberFunction/memberService";
import SidebarWeb from "@/components/menberFunction/functionNavbar/sidebarWeb.vue";
const login = useLogin();
const router = useRouter();
const store = useCounterStore();
const memberService = useMemberService();
const { memberData, isCashMember } = storeToRefs(login);
const { ApiBalance, SortApiBalance, isAllMoneyBacking } =
  storeToRefs(memberService);

function toPath(path: string) {
  router.push({ name: path });
}

// 錢包金額的轉換
const BalanceTranslate = (nBalance: string | number | null) => {
  if (!nBalance) return 0;
  return parseInt(nBalance.toString())
    ? store.addCommas(parseInt(nBalance.toString()).toString())
    : 0;
};

// 一鍵轉回
const BackGame = computed(() => {
  const ApiBalanceAry = Object.values(ApiBalance.value);
  const GameReduce = ApiBalanceAry.reduce((Ary, item) => {
    const nBalance = item.nBalance !== null ? item.nBalance : "null";
    const nBalanceToInt = nBalance ? parseInt(nBalance.toString()) : 0;
    if (!Number.isNaN(nBalanceToInt)) {
      Ary.push(item.sGameType);
    }
    return Ary;
  }, [] as string[]);
  return GameReduce;
});
function getAllMoneyBackData() {
  const sGame = BackGame.value.join(",");
  memberService.getAllMoneyBackData(sGame);
}

function WindowReload() {
  window.location.reload();
}
</script>
<template>
  <div
    class="md:grid grid-cols-12 gap-x-[3vw] block gap-y-[1dvh] px-[1vw] pb-[3rem] pt-0 md:pt-[3rem]">
    <SidebarWeb class="col-span-3 hidden md:block mb-[7dvh]" />
    <div class="col-span-9 relative web-gold-border rounded-[24px]">
      <div class="px-0 py-0 md:px-[5vw] md:py-[2dvh] relative z-10">
        <p class="mt-[1dvh] text-[24px] text-primary font-bold hidden md:block">
          {{ $t("MemberFunction.TakeOverCoin.MyWallet") }}
        </p>
        <div
          class="px-[1em] pt-[1em] pb-[10dvh] max-h-[87dvh] md:max-h-full overflow-y-scroll gamePlatform-scrollbar">
          <div class="z-20 relative member-in-bg w-full h-full">
            <!-- main -->
            <div
              class="text-primary flex justify-around items-center"
              v-if="isCashMember">
              <div
                class="btn-n-border relative flex justify-around items-center px-[3em] md:px-[5em] py-[.5em] md:py-[1em] mt-5"
                @click="toPath('DepositPage')">
                <img
                  class="w-[20px] md:w-[26px] absolute left-[1em] md:left-[2em]"
                  src="@image/memberFunction/05/my_app_deposit.png"
                  alt="" />
                <p class="text-[14px] md:text-[20px] ml-2">
                  {{ $t("MemberFunction.TakeOverCoin.Deposit") }}
                </p>
              </div>
              <div
                class="btn-n-border relative flex justify-around items-center px-[3em] md:px-[5em] py-[.5em] md:py-[1em] mt-5"
                @click="toPath('WithdrawPage')">
                <img
                  class="w-[20px] md:w-[26px] absolute left-[1em] md:left-[2em]"
                  src="@image/memberFunction/05/my_app_pick.png"
                  alt="" />
                <p class="text-[14px] md:text-[20px] ml-2">
                  {{ $t("MemberFunction.TakeOverCoin.Withdraw") }}
                </p>
              </div>
            </div>

            <div class="m-5 md:m-[3vh]">
              <p
                class="text-center text-[14px] md:text-[18px] mt-5 md:mt-[3vh] text-primary tracking-[6px]">
                {{ $t("MemberFunction.TakeOverCoin.MainWallet") }}
              </p>
              <p class="text-5xl font-bold text-center text-primary">
                {{
                  store.getClearPotAndAddCommas(memberData.nMoney).toString()
                }}
              </p>
            </div>

            <div class="text-primary flex justify-center items-center mb-[2em]">
              <button
                type="button"
                class="btn-y-border relative flex justify-around items-center px-[1.3em] md:px-[2em] py-[.3em] md:py-[.3em] mt-5 mr-2 md:mr-[3vw]"
                @click="WindowReload">
                <img
                  class="w-[16px] md:w-[24px] absolute left-[1em]"
                  src="@image/memberFunction/06/money_update_icon.png"
                  alt="" />
                <p class="text-[14px] md:text-[18px] py-1 text-secondary ml-4">
                  {{ $t("MemberFunction.TakeOverCoin.Refresh") }}
                </p>
              </button>
              <button
                type="button"
                class="btn-y-border relative flex justify-around items-center px-[1.3em] md:px-[2em] py-[.3em] md:py-[.3em] mt-5"
                @click="getAllMoneyBackData"
                v-if="BackGame.length > 0">
                <img
                  class="w-[16px] md:w-[24px] absolute left-[1em]"
                  src="@image/memberFunction/06/money_return_icon.png"
                  alt="" />
                <div
                  class="submitting h-[20px] md:h-[30px] w-[80px]"
                  v-if="isAllMoneyBacking"></div>
                <p
                  class="text-[14px] md:text-[18px] py-1 text-secondary ml-4"
                  v-else>
                  {{ $t("MemberFunction.TakeOverCoin.ClickRevert") }}
                </p>
              </button>
            </div>

            <div
              class="text-primary w-full text-xs flex-wrap grid grid-cols-3 md:grid-cols-4 gap-x-[2vw] gap-y-[2dvh] md:gap-x-[1vw] pb-[12px] px-[12px]">
              <div
                class="p-1 text-center"
                v-for="value in SortApiBalance"
                :key="'ApiBalance' + value[0]">
                <div class="relative">
                  <img src="@image/memberFunction/06/money_board.png" alt="" />
                  <div
                    class="absolute w-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                    <p class="text-[12px] md:text-[16px] mb-3">
                      {{ value[0].toString().toUpperCase() }}
                    </p>
                    <p class="text-[20px] md:text-[24px] font-bold text-coin">
                      {{
                        value[1] !== null &&
                        typeof value[1] === "object" &&
                        "nBalance" in value[1]
                          ? BalanceTranslate(value[1].nBalance)
                          : ""
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.bg-100 {
  background-size: 100% 100%;
}
</style>
