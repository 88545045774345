<script setup lang="ts">
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useCounterStore } from "@/stores/counter";
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useTimeListLogic } from "@/stores/timeListLogic";
import SidebarWeb from "@/components/menberFunction/functionNavbar/sidebarWeb.vue";
const store = useCounterStore();
const memberService = useMemberService();
const timeListLogic = useTimeListLogic();
const { defaultBetRecordData } = storeToRefs(memberService);
function changeStartTime() {
  if (
    !defaultBetRecordData.value.sEndTime ||
    defaultBetRecordData.value.sEndTime <= defaultBetRecordData.value.sStartTime
  ) {
    defaultBetRecordData.value.sEndTime = defaultBetRecordData.value.sStartTime;
  }
}

//#region 點擊查詢時間
interface TimeList {
  title: string;
  fn: Function;
  isSelect: boolean;
}
const timeLists = ref<TimeList[]>([
  {
    title: "昨天",
    fn: getYesterdayBetRecord,
    isSelect: false,
  },
  {
    title: "今天",
    fn: getTodayBetRecord,
    isSelect: true,
  },
  {
    title: "上週",
    fn: getLastWeekBetRecord,
    isSelect: false,
  },
  {
    title: "本週",
    fn: getThisWeekBetRecord,
    isSelect: false,
  },
  {
    title: "本月",
    fn: getThisMonthBetRecord,
    isSelect: false,
  },
]);
function selectTime(index: number) {
  timeLists.value.forEach((time, timeIndex) => {
    time.isSelect = false;
    if (timeIndex === index) {
      time.isSelect = true;
    }
  });
}

const today = new Date();
const thirtyDaysAgo = new Date(today);
thirtyDaysAgo.setDate(today.getDate() - 31);
const formatDate = (date: any) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const minDate = ref(formatDate(thirtyDaysAgo));
const maxDate = ref(formatDate(today));

function getYesterdayBetRecord() {
  const today = new Date();
  const day = today.getDay();
  const EndDay = timeListLogic.getCashTypeEndTimeDay(day - 1);
  defaultBetRecordData.value.sStartTime = timeListLogic.getDataDate(day - 1);
  defaultBetRecordData.value.sEndTime = timeListLogic.getDataDate(EndDay);
  selectTime(0);
  getBetRecordData();
}
function getTodayBetRecord() {
  const today = new Date();
  const day = today.getDay();
  const EndDay = timeListLogic.getCashTypeEndTimeDay(day);
  defaultBetRecordData.value.sStartTime = timeListLogic.getDataDate(day);
  defaultBetRecordData.value.sEndTime = timeListLogic.getDataDate(EndDay);

  selectTime(1);
  getBetRecordData();
}

function getLastWeekBetRecord() {
  const EndDay = timeListLogic.getCashTypeEndTimeDay(0);
  defaultBetRecordData.value.sStartTime = timeListLogic.getDataDate(-6);
  defaultBetRecordData.value.sEndTime = timeListLogic.getDataDate(EndDay);

  selectTime(2);
  getBetRecordData();
}

function getThisWeekBetRecord() {
  const EndDay = timeListLogic.getCashTypeEndTimeDay(7);
  defaultBetRecordData.value.sStartTime = timeListLogic.getDataDate(1);
  defaultBetRecordData.value.sEndTime = timeListLogic.getDataDate(EndDay);

  selectTime(3);
  getBetRecordData();
}

function getThisMonthBetRecord() {
  defaultBetRecordData.value.sStartTime = timeListLogic.getMonthFirthDate();
  defaultBetRecordData.value.sEndTime = timeListLogic.getMonthLastDate();

  selectTime(4);
  getBetRecordData();
}
//#endregion

function goBetRecordPage(page: number) {
  memberService.goBetRecordPage(page);
}

function getBetRecordData() {
  memberService.getBetRecordData();
}

function openBetRecordAlert(Id: string) {
  store.toggleAlertBg();
  store.openBetRecordAlert();
  memberService.getBetRecordAlertContent(Id);
}

// 頁碼限制5頁
const getVisiblePageNumbers = computed(() => {
  const start = Math.max(1, memberService.BetRecordPage - 2);
  const end = Math.min(memberService.TotalBetRecordPage, start + 4);
  return Array.from({ length: end - start + 1 }, (_, i) => start + i);
});
</script>
<template>
  <div class="px-[0] mb-[8dvh] md:px-[5dvh]">
    <div
      class="flex flex-col md:flex-row items-center px-[1em] md:px-0 pt-0 md:pt-[1vh]">
      <div class="flex justify-between items-center w-full col-span-3">
        <label
          for="memberNumber"
          class="search-date-label relative w-full md:w-[16vw]">
          <input
            @change="changeStartTime"
            :min="minDate"
            :max="maxDate"
            type="date"
            v-model="defaultBetRecordData.sStartTime"
            class="search-date-border text-primary text-[12px] w-full h-[100%] md:h-[3dvh] bg-transparent text-center bg-cover bg-center placeholder:text-primary" />
        </label>
        <span class="text-primary text-[12px] md:text-[14px] m-[12px]">{{
          $t("MemberFunction.BetRecord.To")
        }}</span>
        <label
          for="memberNumber"
          class="search-date-label relative w-full md:w-[16vw]">
          <input
            type="date"
            :min="minDate"
            :max="maxDate"
            v-model="defaultBetRecordData.sEndTime"
            class="search-date-border text-primary text-[12px] w-full h-[100%] md:h-[3dvh] bg-transparent text-center bg-cover bg-center placeholder:text-primary" />
        </label>
        <button @click="getBetRecordData" class="ml-[12px]">
          <img
            src="@image/memberFunction/08/search_btn.png"
            alt=""
            class="h-auto md:h-[3dvh]" />
        </button>
      </div>
    </div>
    <ul
      class="flex justify-center md:justify-between items-center my-[1dvh] px-[1em] md:px-0">
      <li
        v-for="time in timeLists"
        :key="time.title + 'berRecord'"
        class="flex justify-center md:justify-start items-center">
        <button
          class="group relative w-[80%] md:w-[6em] xl:w-[7em] text-[12px] md:text-[14px] mr-0"
          @click="time.fn"
          :class="{
            'text-primary': !time.isSelect,
            'text-secondary': time.isSelect,
          }">
          <img
            src="@image/memberFunction/common/會員選擇無.png"
            alt=""
            v-show="!time.isSelect" />
          <img
            src="@image/memberFunction/common/會員選擇.png"
            alt=""
            v-show="time.isSelect" />
          <p
            class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 w-[110%]">
            {{ time.title }}
          </p>
        </button>
      </li>
    </ul>
    <div class="w-full h-[3px] bg-white/80 my-[2dvh] hidden md:block"></div>

    <div
      class="relative rounded-lg py-[0px] px-[0] min-h-[50dvh] md:min-h-[70dvh]">
      <!-- main -->
      <div class="text-primary text-[12px] md:text-[14px] w-full">
        <div class="overflow-auto">
          <table class="w-[100%] md:w-full table-auto">
            <thead>
              <tr>
                <th
                  class="w-[23%] h-[45px] text-left pl-[12px] lg:pl-[2em] bg-white/0 md:bg-white/20 rounded-tl-md">
                  {{ $t("MemberFunction.BetRecord.GameType") }}
                </th>
                <th
                  class="w-[18%] h-[45px] text-center bg-white/0 md:bg-white/20">
                  {{ $t("MemberFunction.BetRecord.WagerAmount") }}
                </th>
                <th
                  class="w-[18%] h-[45px] text-center bg-white/0 md:bg-white/20">
                  {{ $t("MemberFunction.BetRecord.ValidBet") }}
                </th>
                <th
                  class="w-[18%] h-[45px] text-center bg-white/0 md:bg-white/20">
                  {{ $t("MemberFunction.BetRecord.WinLose") }}
                </th>
                <th
                  class="h-[45px] text-center pr-[12px] bg-white/0 md:bg-white/20 rounded-tr-md">
                  {{ $t("MemberFunction.BetRecord.Setting") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in memberService.CurrentBetRecord"
                :key="item['1']"
                class="relative odd:bg-black/50 bg-white/10 last:after:h-[0px] after:absolute after:h-[1px] after:bg-white after:left-1/2 after:-translate-x-1/2 after:w-[calc(100%)]">
                <td class="h-[45px] text-left pl-[12px] lg:pl-[2em]">
                  {{ item["2"] }}
                </td>
                <td class="h-[45px] text-center">
                  {{ store.getClearPotAndAddCommas(item["9"]) }}
                </td>
                <td class="h-[45px] text-center">
                  {{ store.getClearPotAndAddCommas(item["9"]) }}
                </td>
                <td
                  class="h-[45px] text-center"
                  :class="{
                    'text-primary':
                      item['13'] &&
                      parseFloat(
                        store.getClearPotAndAddCommas(item['13'].toString())
                      ) === 0,
                    'text-red-600':
                      item['13'] &&
                      parseFloat(
                        store.getClearPotAndAddCommas(item['13'].toString())
                      ) < 0,
                    'text-green-600':
                      item['13'] &&
                      parseFloat(
                        store.getClearPotAndAddCommas(item['13'].toString())
                      ) > 0,
                  }">
                  {{ store.getClearPotAndAddCommas(item["13"]?.toString()) }}
                </td>
                <td
                  class="h-[45px] text-end pr-[12px] flex justify-center items-center">
                  <button
                    type="button"
                    class="btn-n-border"
                    @click="openBetRecordAlert(item['1'])">
                    <p class="text-[12px] px-[1em] py-[.1em]">
                      {{ $t("MemberFunction.BetRecord.Details") }}
                    </p>
                  </button>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th class="h-[45px] text-center pl-[6px] lg:pl-[2em]"></th>
              </tr>
            </tfoot>
          </table>
        </div>
        <div
          class="w-full flex items-center justify-center absolute bottom-[12px] left-1/2 -translate-x-1/2 text-[12px]">
          <button
            type="button"
            :disabled="
              memberService.BetRecordPage === 1 ||
              memberService.TotalBetRecordPage === 0
            "
            @click="goBetRecordPage(1)"
            class="text-[10px] md:text-[16px] mr-[12px]"
            :class="{
              'text-primary/50': memberService.BetRecordPage === 1,
            }"
            v-if="memberService.TotalBetRecordPage > 1">
            {{ $t("MemberFunction.BetRecord.FirstPage") }}
          </button>
          <button
            type="button"
            :disabled="
              memberService.BetRecordPage === 1 ||
              memberService.TotalBetRecordPage === 0
            "
            @click="goBetRecordPage(memberService.BetRecordPage - 1)"
            class="w-[10px]">
            <img src="@image/memberFunction/common/頁面跳轉.png" alt="" />
          </button>
          <div
            class="bg-gray-600 border border-gray-50/80 rounded px-[12px] mx-[6px]">
            <p>
              <span class="text-[14px] font-bold">
                {{ memberService.BetRecordPage }}
              </span>
              <span class="text-[10px] opacity-50">
                /
                {{
                  memberService.TotalBetRecordPage
                    ? memberService.TotalBetRecordPage
                    : 1
                }}
              </span>
            </p>
          </div>
          <!-- <ul class="flex justify-center items-center text-primary">
                <li class="mx-[3px]" v-if="memberService.BetRecordPage >= 4">
                  <button
                    class="font-bold text-primary/50 hover:text-primary"
                    @click="goBetRecordPage(1)"
                  >
                    1..
                  </button>
                </li>
                <li
                  v-for="index in getVisiblePageNumbers"
                  :key="`mailPage${index}`"
                  class="mx-[6px]"
                >
                  <button
                    class="font-bold"
                    :class="
                      index === memberService.BetRecordPage
                        ? 'text-primary'
                        : 'text-primary/50 hover:text-primary'
                    "
                    @click="goBetRecordPage(index)"
                    :disabled="index === memberService.BetRecordPage"
                  >
                    {{ index }}
                  </button>
                </li>
                <li
                  class="mx-[3px]"
                  v-if="
                    memberService.BetRecordPage <=
                    memberService.TotalBetRecordPage - 3
                  "
                >
                  <button
                    class="font-bold text-primary/50 hover:text-primary"
                    @click="goBetRecordPage(memberService.TotalBetRecordPage)"
                  >
                    ..{{ memberService.TotalBetRecordPage }}
                  </button>
                </li>
              </ul> -->
          <button
            type="button"
            class="rotate-180 w-[10px]"
            :disabled="
              memberService.BetRecordPage ===
                memberService.TotalBetRecordPage ||
              memberService.TotalBetRecordPage === 0
            "
            @click="goBetRecordPage(memberService.BetRecordPage + 1)">
            <img src="@image/memberFunction/common/頁面跳轉.png" alt="" />
          </button>
          <button
            type="button"
            :disabled="
              memberService.BetRecordPage ===
                memberService.TotalBetRecordPage ||
              memberService.TotalBetRecordPage === 0
            "
            @click="goBetRecordPage(memberService.TotalBetRecordPage)"
            class="text-[10px] md:text-[16px] ml-[12px]"
            :class="{
              'text-primary/50':
                memberService.BetRecordPage ===
                memberService.TotalBetRecordPage,
            }"
            v-if="memberService.TotalBetRecordPage > 1">
            {{ $t("MemberFunction.BetRecord.LastPage") }}
          </button>
        </div>
      </div>
      <!-- 沒有資料 -->
      <div
        class="absolute left-1/2 top-3/1 -translate-x-1/2"
        v-if="memberService.CurrentBetRecord.length === 0">
        <img
          src="@image/memberFunction/文字框/no_file.png"
          alt=""
          class="w-[35px]" />
        <p class="text-primary text-[12px] text-center">
          {{ $t("MemberFunction.BetRecord.NoData") }}
        </p>
      </div>
    </div>
  </div>
</template>

<style>
.bg-100 {
  background-size: 100% 100%;
}
select {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}
</style>
